import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  HelpCircle, AlertCircle, ExternalLink, Brain, Zap, 
  Clock, Trophy, Users, Target, Sparkles, ArrowRight
} from 'lucide-react';
import { Link } from 'react-router-dom';
import { useApi } from '../hooks/useApi';

const StatCard = ({ icon: Icon, label, value, color }) => (
  <motion.div
    whileHover={{ scale: 1.02 }}
    className={`bg-${color}-500/10 rounded-xl p-4 border border-${color}-500/20`}
  >
    <div className="flex items-center gap-3 mb-2">
      <Icon className={`w-4 h-4 text-${color}-400`} />
      <h3 className={`text-sm text-${color}-400 font-['Space_Grotesk']`}>{label}</h3>
    </div>
    <div className="text-xl font-bold text-white font-['Space_Grotesk']">{value}</div>
  </motion.div>
);

const IndividualTask = ({ house }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [currentHouse, setCurrentHouse] = useState(null);
  const [loading, setLoading] = useState(true);
  const { listHouses } = useApi();

  // Fetch house details when house prop changes
  useEffect(() => {
    const fetchHouseDetails = async () => {
      if (!house) return;
      
      try {
        setLoading(true);
        const houseId = house.house_id;
        if (houseId) {
          const response = await listHouses(houseId);
          if (response && response.house) {
            setCurrentHouse(response.house);
          }
        }
      } catch (error) {
        console.error('Failed to fetch house details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchHouseDetails();
  }, [house]);

  if (loading) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="flex items-center justify-center h-[600px]"
      >
        <motion.div
          animate={{ rotate: 360 }}
          transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
          className="text-purple-500"
        >
          <Brain size={32} />
        </motion.div>
      </motion.div>
    );
  }

  if (!currentHouse) {
    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="relative h-full"
      >
        <motion.div 
          className="bg-gray-800/50 backdrop-blur-xl rounded-2xl p-8 border border-purple-500/20 relative group h-full"
          whileHover={{ scale: 1.02 }}
          transition={{ duration: 0.2 }}
        >
          {/* Animated gradient background */}
          <motion.div
            className="absolute inset-0 bg-gradient-to-br from-purple-500/10 to-blue-500/10 opacity-0 group-hover:opacity-100 rounded-2xl"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0 }}
            whileHover={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
          />

          <div className="h-full flex flex-col items-center justify-center space-y-8 relative z-10">
            {/* Animated icon */}
            <motion.div 
              className="relative"
              animate={{
                scale: [1, 1.1, 1],
              }}
              transition={{
                duration: 4,
                repeat: Infinity,
                ease: "easeInOut"
              }}
            >
              <motion.div
                className="absolute inset-0 bg-purple-500/30 rounded-2xl blur-xl"
                animate={{
                  opacity: [0.5, 0.8, 0.5],
                  scale: [0.95, 1.05, 0.95],
                }}
                transition={{
                  duration: 3,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              />
              <div className="relative p-6 bg-gradient-to-br from-purple-500/20 to-blue-500/20 rounded-2xl border border-purple-500/30">
                <Brain size={48} className="text-purple-400" />
              </div>
            </motion.div>

            {/* Message */}
            <div className="text-center space-y-4">
              <motion.h3
                className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-blue-400 font-['Space_Grotesk']"
                animate={{
                  opacity: [0.8, 1, 0.8],
                }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              >
                No Active Simulations
              </motion.h3>
              
              <p className="text-gray-400 font-['Space_Grotesk'] max-w-sm">
                Begin your journey by exploring and joining simulation houses in the Explorer.
              </p>

              {/* Call to action button */}
              <Link to="/explorer">
                <motion.button
                  className="mt-6 relative px-8 py-3 rounded-xl overflow-hidden group"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <div className="absolute inset-0 bg-gradient-to-r from-purple-600 to-blue-600 opacity-80 group-hover:opacity-100 transition-opacity" />
                  <div className="relative flex items-center gap-3 text-white font-['Space_Grotesk'] tracking-wider">
                    <span>EXPLORE SIMULATIONS</span>
                    <motion.div
                      animate={{
                        x: [0, 5, 0],
                      }}
                      transition={{
                        duration: 1.5,
                        repeat: Infinity,
                        ease: "easeInOut"
                      }}
                    >
                      <ArrowRight size={18} />
                    </motion.div>
                  </div>
                </motion.button>
              </Link>
            </div>
          </div>
        </motion.div>
      </motion.div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="relative"
    >
      <motion.div 
        className="bg-gray-800/50 backdrop-blur-xl rounded-2xl p-8 border border-purple-500/20 relative group"
        whileHover={{ scale: 1.01 }}
        transition={{ duration: 0.2 }}
      >
        {/* Animated gradient background */}
        <motion.div
          className="absolute inset-0 bg-gradient-to-br from-purple-500/10 to-blue-500/10 opacity-0 group-hover:opacity-100 rounded-2xl"
          initial={{ opacity: 0 }}
          animate={{ opacity: 0 }}
          whileHover={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
        />

        {/* Task header with icon */}
        <div className="flex items-center justify-between mb-8">
          <motion.div 
            className="flex items-center gap-4"
            initial={{ x: -20 }}
            animate={{ x: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <motion.div
              whileHover={{ rotate: 360 }}
              transition={{ duration: 0.5 }}
              className="p-3 bg-purple-500/20 rounded-xl border border-purple-500/30"
            >
              <Brain className="w-6 h-6 text-purple-400" />
            </motion.div>
            
            <div>
              <motion.h2
                className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-blue-400 font-['Space_Grotesk']"
                animate={{
                  opacity: [0.9, 1, 0.9],
                }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              >
                {currentHouse.house_name}
              </motion.h2>
              <div className="flex items-center gap-4 mt-1">
                <div className="flex items-center gap-2">
                  <motion.div 
                    className="w-2 h-2 bg-green-400 rounded-full"
                    animate={{
                      scale: [1, 1.2, 1],
                      opacity: [0.5, 1, 0.5]
                    }}
                    transition={{
                      duration: 2,
                      repeat: Infinity
                    }}
                  />
                  <span className="text-green-400 text-sm font-['Space_Grotesk']">ACTIVE</span>
                </div>
                {currentHouse.house_settings?.type === 'virtuals' && (
                  <motion.div 
                    initial={{ opacity: 0, scale: 0 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ type: "spring", stiffness: 500, damping: 30 }}
                    className="px-3 py-1.5 rounded-full bg-lime-500/20 border border-lime-500 flex items-center gap-2"
                  >
                    <div className="w-2 h-2 rounded-full bg-lime-500 animate-pulse" />
                    <span className="text-lime-500 text-sm font-['Space_Grotesk'] uppercase tracking-wider">VIRTUALS</span>
                  </motion.div>
                )}
                <div className="flex items-center gap-2">
                  <Users className="w-4 h-4 text-blue-400" />
                  <span className="text-blue-400 text-sm font-['Space_Grotesk']">{currentHouse.rooms_count} ROOMS</span>
                </div>
              </div>
            </div>
          </motion.div>
        </div>

        {/* Stats Grid */}
        <motion.div 
          className="grid grid-cols-3 gap-4 mb-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
        >
          <StatCard
            icon={Target}
            label="TOTAL SIMULATIONS"
            value={currentHouse.simulation_stats?.total_simulations || '0'}
            color="purple"
          />
          <StatCard
            icon={Trophy}
            label="SUCCESS RATE"
            value={`${currentHouse.simulation_stats?.success_rate?.toFixed(1) || '0'}%`}
            color="green"
          />
          <StatCard
            icon={Clock}
            label="AVG ITERATIONS"
            value={currentHouse.simulation_stats?.average_iterations?.toFixed(1) || '0'}
            color="blue"
          />
        </motion.div>

        {/* House Info Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5 }}
          className="space-y-4 mb-8"
        >
          <h3 className="text-xl text-purple-400 font-['Space_Grotesk'] font-bold flex items-center gap-2">
            House Information <Brain className="w-4 h-4" />
          </h3>
          <div className="bg-gray-900/50 backdrop-blur-sm rounded-xl p-6 border border-gray-700/30">
            <div className="space-y-4">
              <div>
                <h4 className="text-purple-300 text-sm font-['Space_Grotesk'] mb-2">TASK DESCRIPTION</h4>
                <p className="text-gray-300 font-['Chakra_Petch'] tracking-wide leading-relaxed">
                  {currentHouse.task_description || 'No task description available'}
                </p>
              </div>
              <div className="flex items-center gap-4 pt-4 border-t border-gray-700/30">
                <div>
                  <h4 className="text-purple-300 text-xs font-['Space_Grotesk'] mb-1">HOUSE ID</h4>
                  <p className="text-gray-300 font-['Space_Grotesk']">#{currentHouse.house_backend_id}</p>
                </div>
                <div>
                  <h4 className="text-purple-300 text-xs font-['Space_Grotesk'] mb-1">ACTIVE ROOMS</h4>
                  <p className="text-gray-300 font-['Space_Grotesk']">{currentHouse.rooms_count}</p>
                </div>
                <div>
                  <h4 className="text-purple-300 text-xs font-['Space_Grotesk'] mb-1">OWNER</h4>
                  <p className="text-gray-300 font-['Space_Grotesk'] text-sm truncate max-w-[150px]">
                    {currentHouse.owner_wallet_address}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </motion.div>

        {/* Decorative elements */}
        <motion.div
          className="absolute -bottom-2 -right-2 w-32 h-32 bg-gradient-to-br from-purple-500/10 to-blue-500/10 blur-xl rounded-full"
          animate={{
            opacity: [0.3, 0.6, 0.3],
            scale: [0.9, 1.1, 0.9],
          }}
          transition={{
            duration: 4,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        />
      </motion.div>
    </motion.div>
  );
};

export default IndividualTask;