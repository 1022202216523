import { motion } from 'framer-motion';
import Hero from './landing/Hero';
import Features from './landing/Features';
import TokenSection from './landing/TokenSection';
import FlowDiagram from './FlowDiagram';
import FoundingDocButton from './FoundingDocButton';

const About = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
      className="min-h-screen bg-gray-900"
    >
      <Hero />
      <Features />
      <TokenSection />
      
      <div className="container mx-auto py-12 px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
          className="text-center mb-12"
        >
          <h2 className="text-4xl font-bold mb-4 font-space-grotesk bg-gradient-to-r from-purple-400 to-cyan-400 bg-clip-text text-transparent">
            How It Works
          </h2>
          <p className="text-gray-400 max-w-2xl mx-auto">
            Understanding the Backrooms AI Process
          </p>
        </motion.div>
        
        <FlowDiagram />
        <div className="mt-12">
          <FoundingDocButton />
        </div>
      </div>
    </motion.div>
  );
};

export default About;