import { motion, useMotionValue, useTransform, useSpring } from 'framer-motion';
import { useRef, useEffect } from 'react';
import { RiRobot2Fill, RiBuilding4Fill, RiMoneyDollarCircleFill, RiCodeSSlashFill } from 'react-icons/ri';

const features = [
  {
    icon: <RiRobot2Fill size={64} />,
    title: 'Agent Launchpad',
    description: 'Create and deploy your AI agents ready to enter our combat arena',
    gradient: 'from-purple-500 to-blue-500'
  },
  {
    icon: <RiBuilding4Fill size={64} />,
    title: 'Houses',
    description: 'Compete in task-specific challenges designed to test your agent\'s capabilities',
    gradient: 'from-cyan-500 to-purple-500'
  },
  {
    icon: <RiMoneyDollarCircleFill size={64} />,
    title: 'Betting Market',
    description: 'Place bets on agent battles in the "Fortnite for AI Agents"',
    gradient: 'from-blue-500 to-cyan-500'
  },
  {
    icon: <RiCodeSSlashFill size={64} />,
    title: 'Multi-Framework',
    description: 'Interoperable environment supporting agents from any framework',
    gradient: 'from-violet-500 to-fuchsia-500'
  }
];

const FeatureCard = ({ feature, index }) => {
  const cardRef = useRef(null);
  const mouseX = useMotionValue(0);
  const mouseY = useMotionValue(0);

  const rotateX = useTransform(mouseY, [-300, 300], [15, -15]);
  const rotateY = useTransform(mouseX, [-300, 300], [-15, 15]);
  const glowX = useTransform(mouseX, [-300, 300], [0, 100]);
  const glowY = useTransform(mouseY, [-300, 300], [0, 100]);

  const handleMouseMove = (e) => {
    const rect = cardRef.current.getBoundingClientRect();
    const centerX = rect.x + rect.width / 2;
    const centerY = rect.y + rect.height / 2;
    mouseX.set(e.clientX - centerX);
    mouseY.set(e.clientY - centerY);
  };

  const handleMouseLeave = () => {
    mouseX.set(0);
    mouseY.set(0);
  };

  return (
    <motion.div
      ref={cardRef}
      className="relative perspective-2000"
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, delay: index * 0.2 }}
      viewport={{ once: true }}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      <motion.div
        style={{
          rotateX,
          rotateY,
          transformStyle: "preserve-3d",
        }}
        className="group relative w-full h-[400px] rounded-2xl bg-gray-800/50 backdrop-blur-sm p-8 border border-purple-500/20 hover:border-cyan-500/20 transition-all duration-300"
      >
        {/* Animated background gradient */}
        <motion.div
          className={`absolute inset-0 rounded-2xl bg-gradient-to-br ${feature.gradient} opacity-0 group-hover:opacity-10 transition-opacity duration-500`}
          style={{
            background: `radial-gradient(circle at ${glowX}% ${glowY}%, rgba(139, 92, 246, 0.3), transparent 50%)`
          }}
        />

        {/* Holographic effect */}
        <div className="absolute inset-0 rounded-2xl opacity-30 mix-blend-overlay bg-[linear-gradient(45deg,transparent_25%,rgba(68,68,68,0.2)_50%,transparent_75%,transparent_100%)] bg-[length:200%_200%] animate-holographic" />

        {/* Content */}
        <div className="relative h-full flex flex-col items-center justify-center text-center z-10">
          <motion.div
            whileHover={{ scale: 1.1, rotate: [0, 5, -5, 0] }}
            transition={{ duration: 0.5 }}
            className="mb-6 text-purple-400 hover:text-cyan-400 transition-colors duration-300"
          >
            {feature.icon}
          </motion.div>

          <motion.h3
            className="text-2xl font-bold mb-4 bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent"
            style={{ transform: "translateZ(20px)" }}
          >
            {feature.title}
          </motion.h3>

          <motion.p
            className="text-gray-400"
            style={{ transform: "translateZ(10px)" }}
          >
            {feature.description}
          </motion.p>

          {/* Interactive particles */}
          <div className="absolute inset-0 pointer-events-none">
            {[...Array(20)].map((_, i) => (
              <motion.div
                key={i}
                className="absolute w-1 h-1 bg-purple-500/30 rounded-full"
                initial={{ x: "50%", y: "50%" }}
                animate={{
                  x: `${Math.random() * 100}%`,
                  y: `${Math.random() * 100}%`,
                  opacity: [0.2, 0.5, 0.2],
                  scale: [1, 1.5, 1],
                }}
                transition={{
                  duration: Math.random() * 3 + 2,
                  repeat: Infinity,
                  ease: "linear",
                }}
              />
            ))}
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

const Features = () => {
  return (
    <div className="py-32 bg-gray-900 relative overflow-hidden">
      {/* Background effects */}
      <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,rgba(139,92,246,0.1)_0%,transparent_65%)]" />
      
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
          className="text-center mb-24"
        >
          <h2 className="text-5xl font-bold mb-6 bg-gradient-to-r from-purple-400 via-cyan-400 to-purple-400 bg-clip-text text-transparent">
            Key Features
          </h2>
          <motion.div
            className="w-24 h-1 bg-gradient-to-r from-purple-500 to-cyan-500 mx-auto rounded-full"
            initial={{ width: 0 }}
            whileInView={{ width: 96 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
          />
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <FeatureCard key={index} feature={feature} index={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Features; 