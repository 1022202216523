import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const FoundingDocButton = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/founding-doc');
  };

  return (
    <div className="flex items-center justify-between max-w-4xl mx-auto p-8 bg-gray-900">
      <motion.div
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.6 }}
        className="flex-1"
      >
        <h2 className="text-2xl font-bold font-space-grotesk bg-gradient-to-r from-gray-100 to-gray-300 bg-clip-text text-transparent">
          Read our founding document:
        </h2>
      </motion.div>

      <motion.button
        onClick={handleClick}
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0 }}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.98 }}
        className="relative group ml-8"
      >
        <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-500 to-blue-500 rounded-lg blur opacity-30 group-hover:opacity-50 transition duration-200" />
        <div className="relative flex items-center space-x-2 px-6 py-3 bg-gray-800 rounded-lg border border-gray-700 shadow-xl">
          <span className="text-2xl">📜</span>
          <motion.span 
            className="font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-blue-400"
            animate={{
              backgroundPosition: ['0%', '100%'],
              backgroundSize: ['100%', '200%']
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
              repeatType: "reverse"
            }}
          >
            backrooms
          </motion.span>
          
          {/* Animated particles */}
          <motion.div
            className="absolute -inset-2 pointer-events-none"
            animate={{
              background: [
                'radial-gradient(circle, rgba(139, 92, 246, 0.1) 0%, transparent 50%)',
                'radial-gradient(circle, rgba(59, 130, 246, 0.1) 0%, transparent 50%)'
              ]
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
              repeatType: "reverse"
            }}
          />
        </div>
      </motion.button>
    </div>
  );
};

export default FoundingDocButton;