import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useWeb3 } from '../contexts/Web3Context';
import { useApi } from '../hooks/useApi';
import WalletWarning from './WalletWarning';
import HistoryModal from './HistoryModal';
import Loading from './Loading';

const History = () => {
    const [history, setHistory] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedHistoryItem, setSelectedHistoryItem] = useState(null);
    const { isConnected } = useWeb3();
    const { getHistory } = useApi();
  
    useEffect(() => {
      const fetchHistory = async () => {
        if (!isConnected) return;
        
        try {
          const data = await getHistory();
          setHistory(data.user_info);
        } catch (error) {
          console.error('Failed to fetch history:', error);
        } finally {
          setIsLoading(false);
        }
      };
  
      fetchHistory();
    }, [isConnected, getHistory]);
  

  if (!isConnected) {
    return <WalletWarning />;
  }

  // Loading animation
  if (isLoading) {
    return (
      <Loading />
    );
  }

  // Empty state
  if (history.length === 0) {
    return (
      <motion.div 
        className="min-h-[80vh] flex items-center justify-center"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="text-center space-y-6">
          <motion.div 
            className="relative group w-24 h-24 mx-auto"
            whileHover={{ scale: 1.05 }}
          >
            <motion.div
              className="absolute -inset-0.5 bg-gradient-to-br from-purple-500 to-blue-500 rounded-xl blur opacity-75 group-hover:opacity-100 transition duration-200"
              animate={{
                scale: [1, 1.02, 1],
                opacity: [0.5, 0.8, 0.5],
              }}
              transition={{
                duration: 3,
                repeat: Infinity,
                ease: "easeInOut"
              }}
            />
            <div className="relative h-full bg-gray-800 rounded-xl flex items-center justify-center">
              <motion.span 
                className="text-4xl"
                animate={{
                  rotate: [0, -10, 10, -10, 10, 0],
                }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              >
                📜
              </motion.span>
            </div>
          </motion.div>

          <motion.div className="space-y-2">
            <motion.h2 
              className="text-2xl text-white font-['Space_Grotesk'] font-bold tracking-wide"
              animate={{
                opacity: [1, 0.8, 1],
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                ease: "easeInOut"
              }}
            >
              No History Yet
            </motion.h2>
            
            <motion.p 
              className="text-gray-400 font-['Chakra_Petch'] tracking-wide"
            >
              Your simulation history will appear here
            </motion.p>
          </motion.div>
        </div>
      </motion.div>
    );
  }

  // History list with animations
  return (
    <>
      <motion.div 
        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <motion.h2 
          className="text-2xl font-bold text-white mb-6 font-['Space_Grotesk'] tracking-wide"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Simulation History
        </motion.h2>
        <motion.div 
          className="space-y-4"
          initial="hidden"
          animate="visible"
          variants={{
            visible: {
              transition: {
                staggerChildren: 0.1
              }
            }
          }}
        >
          {history.map((item, index) => (
            <motion.div
              key={index}
              className="bg-gray-800 rounded-xl p-6 border border-gray-700 relative overflow-hidden group cursor-pointer"
              variants={{
                hidden: { opacity: 0, y: 20 },
                visible: { opacity: 1, y: 0 }
              }}
              whileHover={{ scale: 1.01 }}
              onClick={() => setSelectedHistoryItem(item)}
            >
              <motion.div 
                className="absolute inset-0 bg-gradient-to-r from-purple-500/10 to-blue-500/10 opacity-0 group-hover:opacity-100"
                transition={{ duration: 0.2 }}
              />
              <div className="flex justify-between items-start mb-4">
                <div className="font-['Chakra_Petch'] text-purple-400 tracking-wider">
                  {new Date(item.timestamp).toLocaleString()}
                </div>
                <div className="flex items-center gap-2">
                  <div className="px-3 py-1 rounded-full bg-gray-700 text-gray-300 text-sm font-['Space_Grotesk']">
                    ID: {item.simulation_backend_id}
                  </div>
                </div>
              </div>
              <div className="text-gray-300 font-['Space_Grotesk'] tracking-wide line-clamp-2">
                {item.conversation[0]?.creator || "No conversation available"}
              </div>
              <div className="mt-4 text-sm text-gray-400">
                Click to view full conversation
              </div>
            </motion.div>
          ))}
        </motion.div>
      </motion.div>

      <HistoryModal 
        isOpen={!!selectedHistoryItem}
        onClose={() => setSelectedHistoryItem(null)}
        historyItem={selectedHistoryItem}
      />
    </>
  );
};

export default History;