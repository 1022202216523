import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Brain, Sparkles } from 'lucide-react';
import { useApi } from '../hooks/useApi';

const AgentCreator = ({ 
  isPromptSubmitted, 
  setIsPromptSubmitted, 
  isLoading, 
  prompt, 
  setPrompt, 
  currentRoomId,
  fetchRooms,
  roomType,
  virtualUid: initialVirtualUid = '',
  additionalContext: initialAdditionalContext = ''
}) => {
  const [virtualUid, setVirtualUid] = useState(initialVirtualUid);
  const [additionalContext, setAdditionalContext] = useState(initialAdditionalContext);
  const { setRoomPrompt, setVirtualsRoomSettings } = useApi();

  useEffect(() => {
    if (roomType === 'virtuals') {
      setVirtualUid(initialVirtualUid);
      setAdditionalContext(initialAdditionalContext);
      setIsPromptSubmitted(!!initialVirtualUid && !!initialAdditionalContext);
    }
  }, [initialVirtualUid, initialAdditionalContext, roomType, setIsPromptSubmitted]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      if (roomType === 'simple_prompt') {
        if (!currentRoomId || !prompt) {
          console.error('Missing required data for simple prompt:', { currentRoomId, prompt });
          return;
        }
        await setRoomPrompt(currentRoomId, prompt);
      } else {
        if (!currentRoomId || !virtualUid || !additionalContext) {
          console.error('Missing required data for virtuals:', { currentRoomId, virtualUid, additionalContext });
          return;
        }
        
        console.log('Setting virtuals room settings:', {
          roomId: currentRoomId,
          virtualUid,
          additionalContext
        });
        
        await setVirtualsRoomSettings(currentRoomId, virtualUid, additionalContext);
      }
      setIsPromptSubmitted(true);
      await fetchRooms();
    } catch (error) {
      console.error('Failed to set room settings:', error);
      setIsPromptSubmitted(false);
    }
  };

  const isVirtualsRoom = roomType === 'virtuals';
  const isVirtualConfigured = isVirtualsRoom && !!virtualUid && !!additionalContext;

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className={`bg-gray-800/50 backdrop-blur-xl rounded-2xl p-8 border ${
        isVirtualsRoom ? 'border-lime-500/20' : 'border-purple-500/20'
      } relative group`}
    >
      <div className="flex items-center gap-3 mb-6">
        <motion.div 
          whileHover={{ rotate: 360 }}
          transition={{ duration: 0.5 }}
          className={`p-3 rounded-lg ${
            isVirtualsRoom 
              ? 'bg-lime-500/20 border-lime-500/30' 
              : 'bg-purple-500/20 border-purple-500/30'
          } border`}
        >
          <Brain className={`w-6 h-6 ${isVirtualsRoom ? 'text-lime-500' : 'text-purple-400'}`} />
        </motion.div>
        <div>
          <h2 className="text-2xl font-bold text-white font-['Space_Grotesk']">
            {isVirtualsRoom ? 'Configure Virtual Agent' : 'Configure Agent'}
          </h2>
          <div className="flex items-center gap-2 mt-1">
            <div className={`w-2 h-2 rounded-full ${isVirtualsRoom ? 'bg-lime-500' : 'bg-purple-400'} animate-pulse`} />
            <span className={`text-sm font-['Space_Grotesk'] ${isVirtualsRoom ? 'text-lime-500' : 'text-purple-400'}`}>
              {isVirtualsRoom ? 'VIRTUALS' : 'PROMPTOOOOR'}
            </span>
          </div>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        {!isVirtualsRoom ? (
          <div className="space-y-2">
            <label className="block text-sm text-gray-400 font-['Space_Grotesk']">System Prompt</label>
            <textarea
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              rows={6}
              className="w-full px-4 py-3 bg-gray-900/50 border border-gray-700 rounded-lg focus:border-purple-500 focus:ring-2 focus:ring-purple-500/50 text-white placeholder-gray-500 font-['Space_Grotesk']"
              placeholder="Define the agent's behavior and personality..."
            />
          </div>
        ) : (
          <>
            <div className="space-y-2">
              <label className="block text-sm text-lime-500 font-['Space_Grotesk']">Virtual UID</label>
              <input
                type="text"
                value={virtualUid}
                onChange={(e) => setVirtualUid(e.target.value)}
                className="w-full px-4 py-3 bg-gray-900/50 border border-gray-700 rounded-lg focus:border-lime-500 focus:ring-2 focus:ring-lime-500/50 text-white placeholder-gray-500 font-['Space_Grotesk']"
                placeholder="Enter virtual UID..."
              />
            </div>
            <div className="space-y-2">
              <label className="block text-sm text-lime-500 font-['Space_Grotesk']">Additional Context</label>
              <textarea
                value={additionalContext}
                onChange={(e) => setAdditionalContext(e.target.value)}
                rows={4}
                className="w-full px-4 py-3 bg-gray-900/50 border border-gray-700 rounded-lg focus:border-lime-500 focus:ring-2 focus:ring-lime-500/50 text-white placeholder-gray-500 font-['Space_Grotesk']"
                placeholder="Define additional context for the virtual agent..."
              />
              <p className="text-xs text-gray-500 italic">Note: "You are on the Backrooms AI platform." will be automatically prepended.</p>
            </div>
          </>
        )}

        <motion.button
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          type="submit"
          disabled={isLoading || (isVirtualsRoom && (!virtualUid || !additionalContext))}
          className={`w-full relative px-8 py-4 rounded-xl overflow-hidden group ${
            isLoading || (isVirtualsRoom && (!virtualUid || !additionalContext))
              ? 'opacity-50 cursor-not-allowed'
              : ''
          }`}
        >
          <div className={`absolute inset-0 ${
            isVirtualsRoom
              ? 'bg-gradient-to-r from-lime-500 to-green-500'
              : 'bg-gradient-to-r from-purple-600 to-blue-600'
          } opacity-80 group-hover:opacity-100 transition-opacity`} />
          <span className="relative text-white font-['Space_Grotesk'] tracking-wider text-lg flex items-center justify-center gap-2">
            {isLoading ? 'CONFIGURING...' : isVirtualConfigured ? 'CONFIGURED' : 'CONFIGURE AGENT'} <Sparkles className="w-5 h-5" />
          </span>
        </motion.button>
      </form>
    </motion.div>
  );
};

export default AgentCreator;