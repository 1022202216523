import React from 'react'
import { motion } from 'framer-motion';

const WalletWarning = () => {

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
          opacity: 1,
          transition: {
            duration: 0.5,
            when: "beforeChildren",
            staggerChildren: 0.2
          }
        }
      };
      
      const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
          opacity: 1,
          y: 0,
          transition: { duration: 0.5 }
        }
      };

    return (
        <motion.div 
          className="min-h-[80vh] flex items-center justify-center"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="text-center space-y-4">
            <motion.div 
              className="relative group"
              variants={itemVariants}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <div className="absolute -inset-0.5 bg-gradient-to-br from-purple-500 to-blue-500 rounded-xl blur opacity-75 group-hover:opacity-100 transition duration-200" />
              <div className="relative w-16 h-16 mx-auto bg-gradient-to-br from-purple-500 to-blue-500 rounded-xl flex items-center justify-center">
                <motion.div 
                  className="text-2xl"
                  animate={{ 
                    scale: [1, 1.1, 1],
                    rotate: [0, 5, -5, 0]
                  }}
                  transition={{ 
                    duration: 2,
                    repeat: Infinity,
                    repeatType: "reverse"
                  }}
                >
                  🔒
                </motion.div>
              </div>
            </motion.div>
            
            <motion.div
              variants={itemVariants}
              className="text-xl text-gray-300 font-['Space_Grotesk'] tracking-wide"
            >
              <motion.span
                animate={{
                  opacity: [1, 0.7, 1],
                }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              >
                Please connect your wallet to continue
              </motion.span>
            </motion.div>
          </div>
        </motion.div>
      );
}

export default WalletWarning