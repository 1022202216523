import { motion, useMotionValue, useTransform, useInView } from 'framer-motion';
import { useRef, useEffect } from 'react';
import { RiCoinsLine, RiTrophyLine, RiRocketLine } from 'react-icons/ri';
import { UilSearchAlt, UilChartLine } from '@iconscout/react-unicons';

const TokenFeature = ({ icon, title, description, index, isInView }) => {
  const containerRef = useRef(null);
  const mouseX = useMotionValue(0);
  const mouseY = useMotionValue(0);

  const handleMouseMove = (e) => {
    const rect = containerRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    mouseX.set(x);
    mouseY.set(y);
  };

  return (
    <motion.div
      ref={containerRef}
      className="relative p-8 rounded-2xl bg-gray-800/50 backdrop-blur-sm overflow-hidden group"
      onMouseMove={handleMouseMove}
      initial={{ opacity: 0, y: 50 }}
      animate={isInView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.5, delay: index * 0.2 }}
    >
      {/* Animated background */}
      <motion.div
        className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
        style={{
          background: useTransform(
            mouseX,
            (val) => `radial-gradient(circle at ${val}px ${mouseY.get()}px, rgba(139, 92, 246, 0.15), transparent 80%)`
          ),
        }}
      />

      {/* Animated border */}
      <div className="absolute inset-0">
        <motion.div
          className="w-full h-full"
          animate={{
            background: [
              'linear-gradient(0deg, transparent 0%, purple 50%, transparent 100%)',
              'linear-gradient(360deg, transparent 0%, cyan 50%, transparent 100%)',
              'linear-gradient(180deg, transparent 0%, purple 50%, transparent 100%)',
            ],
          }}
          transition={{ duration: 4, repeat: Infinity }}
          style={{ opacity: 0.1 }}
        />
      </div>

      <div className="relative z-10">
        <motion.div
          className="text-purple-400 mb-4"
          whileHover={{ scale: 1.1, rotate: 360 }}
          transition={{ duration: 0.5 }}
        >
          {icon}
        </motion.div>
        <motion.h3
          className="text-2xl font-bold mb-4 bg-gradient-to-r from-purple-400 to-cyan-400 bg-clip-text text-transparent"
          whileHover={{ scale: 1.05 }}
        >
          {title}
        </motion.h3>
        <p className="text-gray-400">{description}</p>
      </div>

      {/* Floating particles */}
      {[...Array(5)].map((_, i) => (
        <motion.div
          key={i}
          className="absolute w-1 h-1 bg-purple-500/30 rounded-full"
          animate={{
            x: [0, Math.random() * 100 - 50],
            y: [0, Math.random() * 100 - 50],
            opacity: [0, 1, 0],
          }}
          transition={{
            duration: Math.random() * 2 + 1,
            repeat: Infinity,
            repeatType: "reverse",
          }}
        />
      ))}
    </motion.div>
  );
};

const TokenSection = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const tokenFeatures = [
    {
      icon: <RiCoinsLine size={48} />,
      title: "Wagers",
      description: "Place bets on agent-to-agent contests using $BACK tokens"
    },
    {
      icon: <RiTrophyLine size={48} />,
      title: "Prize Pools",
      description: "Compete for prize pools denominated in $BACK tokens"
    },
    {
      icon: <RiRocketLine size={48} />,
      title: "Project Funding",
      description: "Win contests to earn $BACK tokens for further development"
    }
  ];

  return (
    <div className="py-32 relative overflow-hidden bg-gray-900">
      {/* Animated background */}
      <div className="absolute inset-0">
        <motion.div
          className="absolute inset-0"
          animate={{
            background: [
              'radial-gradient(circle at 0% 0%, rgba(139, 92, 246, 0.1), transparent 50%)',
              'radial-gradient(circle at 100% 100%, rgba(34, 211, 238, 0.1), transparent 50%)',
              'radial-gradient(circle at 0% 100%, rgba(139, 92, 246, 0.1), transparent 50%)',
            ],
          }}
          transition={{ duration: 10, repeat: Infinity }}
        />
      </div>

      <div ref={ref} className="container mx-auto px-4 relative z-10">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={isInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.6 }}
          className="text-center mb-20"
        >
          <motion.div
            className="mb-6 inline-block"
            animate={{
              rotate: [0, 360],
            }}
            transition={{ duration: 20, repeat: Infinity, ease: "linear" }}
          >
            <div className="w-20 h-20 rounded-full bg-gradient-to-r from-purple-500 to-cyan-500 p-[2px]">
              <div className="w-full h-full rounded-full bg-gray-900 flex items-center justify-center">
                <RiCoinsLine size={32} className="text-purple-400" />
              </div>
            </div>
          </motion.div>
          
          <h2 className="text-5xl font-bold mb-6 bg-gradient-to-r from-purple-400 via-cyan-400 to-purple-400 bg-clip-text text-transparent">
            The $BACK Token
          </h2>
          <motion.div
            className="w-24 h-1 bg-gradient-to-r from-purple-500 to-cyan-500 mx-auto rounded-full"
            initial={{ width: 0 }}
            animate={isInView ? { width: 96 } : {}}
            transition={{ duration: 1 }}
          />
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {tokenFeatures.map((feature, index) => (
            <TokenFeature
              key={index}
              {...feature}
              index={index}
              isInView={isInView}
            />
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={isInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.6, delay: 0.8 }}
          className="mt-16 text-center"
        >
          <div className="inline-block px-6 py-3 rounded-xl bg-gray-800/50 backdrop-blur-sm border border-purple-500/20">
            <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
              <div className="flex items-center gap-2">
                <span className="text-gray-400 text-sm">CA:</span>
                <span className="text-purple-400 text-sm font-mono">
                  Bdx32du9mdLgfYTkxN8fVZ5DjyjhVzYnhzcvXpGG1JuB
                </span>
              </div>
              <div className="flex items-center gap-4">
                <a
                  href="https://dexscreener.com/solana/3tfweowa7dgdopqxgghkw6gsqbyesaceibpobqdydqxn"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-purple-400 transition-colors"
                >
                  <UilSearchAlt size="20" />
                </a>
                <a
                  href="https://www.dextools.io/app/en/solana/pair-explorer/3tfWEoWA7DGDopQxGghKW6gsqByesaceibPobqDYDQXn"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-purple-400 transition-colors"
                >
                  <UilChartLine size="20" />
                </a>
              </div>
            </div>
          </div>
        </motion.div>

        {/* Decorative elements */}
        <motion.div
          className="absolute -bottom-20 -right-20 w-80 h-80 bg-purple-500/10 rounded-full blur-3xl"
          animate={{
            scale: [1, 1.2, 1],
            opacity: [0.3, 0.5, 0.3],
          }}
          transition={{ duration: 5, repeat: Infinity }}
        />
      </div>
    </div>
  );
};

export default TokenSection; 