import React from 'react';
import { motion } from 'framer-motion';

const Loading = ({ text = "Loading...", className = "" }) => {
  return (
    <div className={`min-h-[80vh] flex items-center justify-center ${className}`}>
      <motion.div 
        className="space-y-6 text-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <motion.div 
          className="w-16 h-16 mx-auto"
          animate={{
            rotate: 360,
            scale: [1, 1.2, 1],
          }}
          transition={{
            rotate: {
              duration: 2,
              repeat: Infinity,
              ease: "linear"
            },
            scale: {
              duration: 1,
              repeat: Infinity,
              ease: "easeInOut"
            }
          }}
        >
          <div className="w-full h-full rounded-full border-4 border-purple-500 border-t-transparent" />
        </motion.div>
        
        <motion.div 
          className="flex justify-center space-x-1"
        >
          {[0, 1, 2].map((dot) => (
            <motion.div
              key={dot}
              className="w-2 h-2 bg-purple-500 rounded-full"
              animate={{
                scale: [1, 1.5, 1],
                opacity: [0.5, 1, 0.5],
              }}
              transition={{
                duration: 1,
                repeat: Infinity,
                delay: dot * 0.2,
                ease: "easeInOut"
              }}
            />
          ))}
        </motion.div>
        
        <motion.div 
          className="text-white font-['Space_Grotesk'] tracking-wide"
          animate={{
            opacity: [1, 0.7, 1],
          }}
          transition={{
            duration: 1.5,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        >
          {text}
        </motion.div>
      </motion.div>
    </div>
  );
};

export default Loading;