import React, { useState, useEffect } from 'react';
import { useWeb3 } from '../contexts/Web3Context';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import AgentCreator from './AgentCreator';
import IndividualTask from './IndividualTask';
import SimulationControls from './SimulationControls';
import WalletWarning from './WalletWarning';
import ConversationModal from './ConversationModal';
import { useApi } from '../hooks/useApi';
import { Brain } from 'lucide-react';

const UserAccount = () => {
  const { isConnected } = useWeb3();
  const [isSimulating, setIsSimulating] = useState(false);
  const [messages, setMessages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPromptSubmitted, setIsPromptSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentHouseIndex, setCurrentHouseIndex] = useState(0);
  const [houses, setHouses] = useState([]);
  const [prompt, setPrompt] = useState('');
  const [initialPrompt, setInitialPrompt] = useState('');
  const [currentRoomId, setCurrentRoomId] = useState('');
  const { listRooms, setRoomPrompt } = useApi();

  console.log(houses);


  const fetchRooms = async () => {
    try {
      setIsLoading(true);
      const response = await listRooms();
      
      if (response.status === 200 && response.rooms) {
        setHouses(response.rooms);
        
        if (response.rooms.length > 0) {
          const firstRoom = response.rooms[0];
          setCurrentRoomId(firstRoom.room_id);
          
          // Check room type and set appropriate states
          if (firstRoom.room_settings?.type === 'simple_prompt') {
            setPrompt(firstRoom.room_settings.system_prompt || '');
            setInitialPrompt(firstRoom.room_settings.system_prompt || '');
            setIsPromptSubmitted(!!firstRoom.room_settings.system_prompt);
          } else if (firstRoom.room_settings?.type === 'virtuals') {
            setPrompt('');
            setInitialPrompt('');
            setIsPromptSubmitted(!!firstRoom.room_settings.virtual_uid);
          }
        }
      } else {
        setHouses([]);
      }
    } catch (error) {
      console.error('Failed to fetch rooms:', error);
      setHouses([]);
    } finally {
      setIsLoading(false);
    }
  };

  console.log('current room id is ', currentRoomId);

  useEffect(() => {

    if (isConnected) {
      fetchRooms();
    }
  }, [isConnected, listRooms]);

  // Update room prompt when switching houses
  useEffect(() => {
    if (houses.length > 0) {
      const currentHouse = houses[currentHouseIndex];
      setCurrentRoomId(currentHouse.room_id);
      
      console.log('Current house settings:', currentHouse.room_settings);
      
      if (currentHouse.room_settings?.type === 'simple_prompt') {
        setPrompt(currentHouse.room_settings.system_prompt || '');
        setInitialPrompt(currentHouse.room_settings.system_prompt || '');
        setIsPromptSubmitted(!!currentHouse.room_settings.system_prompt);
      } else if (currentHouse.room_settings?.type === 'virtuals') {
        setPrompt('');
        setInitialPrompt('');
        // Check both virtual_uid and additional_context
        const hasVirtualUid = !!currentHouse.room_settings.virtual_uid;
        const hasAdditionalContext = !!currentHouse.room_settings.additional_context;
        console.log('Virtuals configuration:', { 
          hasVirtualUid, 
          hasAdditionalContext,
          virtualUid: currentHouse.room_settings.virtual_uid,
          additionalContext: currentHouse.room_settings.additional_context 
        });
        setIsPromptSubmitted(hasVirtualUid && hasAdditionalContext);
      }
    }
  }, [currentHouseIndex, houses]);

  // Handle prompt updates
  useEffect(() => {
    const updateRoomPrompt = async () => {
      if (isPromptSubmitted && currentRoomId && prompt !== initialPrompt) {
        try {
          await setRoomPrompt(currentRoomId, prompt);
          setInitialPrompt(prompt);
        } catch (error) {
          console.error('Failed to update room prompt:', error);
          // Reset prompt to initial state if update fails
          setPrompt(initialPrompt);
          setIsPromptSubmitted(false);
        }
      }
    };

    updateRoomPrompt();
  }, [isPromptSubmitted, currentRoomId, prompt, initialPrompt, setRoomPrompt]);

  const handleNextHouse = () => {
    if (currentHouseIndex < houses.length - 1) {
      setCurrentHouseIndex(prev => prev + 1);
    }
  };

  const handlePreviousHouse = () => {
    if (currentHouseIndex > 0) {
      setCurrentHouseIndex(prev => prev - 1);
    }
  };

  if (!isConnected) {
    return <WalletWarning />;
  }

  const currentHouse = houses[currentHouseIndex];
  const isVirtualsRoom = currentHouse?.room_settings?.type === 'virtuals';

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {currentHouse && (
          <AgentCreator 
            isPromptSubmitted={isPromptSubmitted}
            setIsPromptSubmitted={setIsPromptSubmitted}
            isLoading={isLoading}
            prompt={prompt}
            initialPrompt={initialPrompt}
            setInitialPrompt={setInitialPrompt}
            setPrompt={setPrompt}
            currentRoomId={currentRoomId}
            fetchRooms={fetchRooms}
            roomType={currentHouse.room_settings?.type || 'simple_prompt'}
            virtualUid={currentHouse.room_settings?.virtual_uid || ''}
            additionalContext={currentHouse.room_settings?.additional_context?.replace('You are on the Backrooms AI platform. ', '') || ''}
          />
        )}
        
        <div className="relative">
          {houses.length > 0 && (
            <div className="absolute -top-12 right-0 flex items-center space-x-4 z-10 mt-7 pb-7">
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={handlePreviousHouse}
                disabled={currentHouseIndex === 0}
                className="p-2 rounded-lg bg-gray-700/50 hover:bg-gray-600 text-gray-300 hover:text-white transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <ChevronLeft size={24} />
              </motion.button>
              
              <span className="text-gray-400 font-['Space_Grotesk']">
                House {currentHouseIndex + 1} of {houses.length}
              </span>
              
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={handleNextHouse}
                disabled={currentHouseIndex === houses.length - 1}
                className="p-2 rounded-lg bg-gray-700/50 hover:bg-gray-600 text-gray-300 hover:text-white transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <ChevronRight size={24} />
              </motion.button>
            </div>
          )}
          
          <AnimatePresence mode="wait">
            <motion.div
              key={1}
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -50 }}
              transition={{ duration: 0.3 }}
              className='mt-7'
            >
              <IndividualTask house={houses[currentHouseIndex]} />
            </motion.div>
          </AnimatePresence>
        </div>
      </div>

      <SimulationControls
        isSimulating={isSimulating}
        setIsSimulating={setIsSimulating}
        isPromptSubmitted={isPromptSubmitted}
        setMessages={setMessages}
        onViewConversation={() => setIsModalOpen(true)}
        currentRoomId={currentRoomId}
      />

      <ConversationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        messages={messages}
        isSimulating={isSimulating}
      />
    </div>
  );
};

export default UserAccount;