import React from 'react';
import { motion } from 'framer-motion';
import { MessageCircle, Download, Clock } from 'lucide-react';
import Modal from './Modal';

const MessageCard = ({ message, index }) => {
  const role = Object.keys(message)[0];
  const content = Object.values(message)[0];
  
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: index * 0.1 }}
      className="bg-gray-900/50 backdrop-blur-sm rounded-xl p-5 border border-gray-700/50 hover:border-gray-600/50 transition-colors"
    >
      <div className="font-['Chakra_Petch'] tracking-wider text-purple-400 mb-2 uppercase text-sm">
        {role}
      </div>
      <div className="text-gray-300 font-['Space_Grotesk'] tracking-wide whitespace-pre-wrap text-sm leading-relaxed">
        {content}
      </div>
    </motion.div>
  );
};

const HistoryModal = ({ isOpen, onClose, historyItem }) => {
  const downloadConversation = () => {
    console.log(historyItem);
    if (!historyItem?.conversation) return;
    console.log(historyItem.conversation);
    
    const text = historyItem.conversation
      .map(m => `${Object.keys(m)[0]}: ${Object.values(m)[0]}`)
      .join('\n\n');
    
    const blob = new Blob([text], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `conversation-${new Date().toISOString()}.txt`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const AnimatedButton = ({ children, onClick, className }) => (
    <motion.button
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      onClick={onClick}
      className={className}
    >
      {children}
    </motion.button>
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="p-8">
        <motion.div 
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.1 }}
          className="flex justify-between items-center mb-8"
        >
          <div className="flex items-center space-x-4">
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ delay: 0.2, type: "spring" }}
            >
              <MessageCircle size={32} className="text-purple-400" />
            </motion.div>
            <div className="space-y-2">
              <h2 className="text-2xl font-bold text-gray-100 font-['Space_Grotesk']">
                Past Conversation
              </h2>
              <div className="flex items-center gap-2 text-gray-400">
                <Clock size={14} />
                <span className="text-sm font-['Space_Grotesk']">
                  {new Date(historyItem?.timestamp).toLocaleString()}
                </span>
              </div>
            </div>
          </div>
          
          <AnimatedButton
            onClick={downloadConversation}
            className="relative px-4 py-2.5 rounded-xl overflow-hidden group"
          >
            <div className="absolute inset-0 bg-gradient-to-r from-blue-500 to-purple-500 opacity-80 group-hover:opacity-100 transition-opacity" />
            <div className="relative flex items-center space-x-2 text-white font-['Chakra_Petch'] tracking-wider">
              <Download size={16} />
              <span>DOWNLOAD</span>
            </div>
          </AnimatedButton>
        </motion.div>

        <motion.div 
          className="space-y-4 max-h-[60vh] overflow-y-auto pr-4 custom-scrollbar"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
        >
          {historyItem?.conversation?.map((message, index) => (
            <MessageCard key={index} message={message} index={index} />
          ))}
        </motion.div>

        {historyItem?.simulation_backend_id && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
            className="mt-6 p-5 bg-gray-900/50 backdrop-blur-sm rounded-xl border border-gray-700/50"
          >
            <p className="text-gray-300 text-sm flex items-center space-x-2">
              <span className="text-purple-400">Simulation ID:</span>
              <span>{historyItem.simulation_backend_id}</span>
            </p>
          </motion.div>
        )}
      </div>
    </Modal>
  );
};

export default HistoryModal;