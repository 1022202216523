import { motion } from 'framer-motion';
import { useState } from 'react';

const CyberButton = ({ children, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <motion.button
      className="relative group"
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={onClick}
    >
      {/* Animated background layers */}
      <motion.div
        className="absolute inset-0 bg-gradient-to-r from-purple-600/50 to-cyan-500/50 rounded-lg blur-xl"
        animate={{
          scale: isHovered ? [1, 1.2, 1.1] : 1,
          opacity: isHovered ? [0.5, 0.8, 0.6] : 0.5,
        }}
        transition={{ duration: 1, repeat: Infinity }}
      />

      {/* Button content container */}
      <div className="relative px-12 py-4 rounded-lg bg-gray-900/50 backdrop-blur-sm border border-purple-500/30 overflow-hidden">
        {/* Animated lines */}
        <div className="absolute inset-0">
          <motion.div
            className="absolute inset-0 border-2 border-transparent"
            style={{
              background: 'linear-gradient(90deg, transparent, rgba(139, 92, 246, 0.3), transparent)',
              maskImage: 'linear-gradient(90deg, transparent, white, transparent)',
              WebkitMaskImage: 'linear-gradient(90deg, transparent, white, transparent)',
            }}
            animate={{
              x: [-500, 500],
            }}
            transition={{
              duration: 3,
              repeat: Infinity,
              ease: "linear",
            }}
          />
        </div>

        {/* Corner accents */}
        {[0, 1, 2, 3].map((i) => (
          <motion.div
            key={i}
            className="absolute w-2 h-2"
            style={{
              top: i < 2 ? -1 : 'auto',
              bottom: i >= 2 ? -1 : 'auto',
              left: i % 2 === 0 ? -1 : 'auto',
              right: i % 2 === 1 ? -1 : 'auto',
              background: 'linear-gradient(45deg, #9333ea, #22d3ee)',
            }}
            animate={isHovered ? {
              scale: [1, 1.5, 1],
              opacity: [0.5, 1, 0.5],
            } : {}}
            transition={{ duration: 1, repeat: Infinity }}
          />
        ))}

        {/* Text content */}
        <motion.span
          className="relative z-10 text-xl font-bold text-white font-space-grotesk tracking-wider"
          animate={{
            textShadow: isHovered 
              ? ['0 0 10px rgba(147, 51, 234, 0)', '0 0 20px rgba(147, 51, 234, 0.5)', '0 0 10px rgba(147, 51, 234, 0)']
              : '0 0 0px rgba(147, 51, 234, 0)',
          }}
          transition={{ duration: 1, repeat: Infinity }}
        >
          {children}
        </motion.span>
      </div>
    </motion.button>
  );
};

export default CyberButton; 