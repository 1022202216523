import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Home, ArrowLeft, Loader, Sparkles, Users, Trophy, Zap, Clock, Brain, Shield } from 'lucide-react';
import { useApi } from '../hooks/useApi';
import angel from "../assets/xpaHutel.jpg";

const IndividualHouse = () => {
  const { house_id } = useParams();
  const navigate = useNavigate();
  const { listHouses, enterRoom } = useApi();
  const [house, setHouse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isEntering, setIsEntering] = useState(false);

  useEffect(() => {
    const fetchHouseDetails = async () => {
      try {
        setLoading(true);
        const response = await listHouses(house_id);
        if (response && response.house && response.house) {
          setHouse(response.house);
        }
      } catch (error) {
        console.error('Failed to fetch house details:', error);
        navigate('/explorer');
      } finally {
        setLoading(false);
      }
    };

    fetchHouseDetails();
  }, [house_id]);

  const handleEnterHouse = async (roomType = 'simple_prompt') => {
    try {
      setIsEntering(true);
      await enterRoom(parseInt(house_id, 10), roomType);
      setTimeout(() => {
        setIsEntering(false);
        navigate('/account');
      }, 2000);
    } catch (error) {
      console.error('Failed to enter house:', error);
      setIsEntering(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <motion.div
          animate={{ rotate: 360 }}
          transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
          className="text-purple-500"
        >
          <Loader size={32} />
        </motion.div>
      </div>
    );
  }

  if (!house) {
    return null;
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="min-h-screen bg-gradient-to-b from-gray-900 via-purple-900/10 to-gray-900"
    >
      <AnimatePresence>
        {isEntering && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-50 flex items-center justify-center bg-black/80 backdrop-blur-md"
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              className="relative"
            >
              <motion.div
                className="absolute -inset-4"
                animate={{
                  background: [
                    "radial-gradient(circle at center, rgba(139, 92, 246, 0.3) 0%, transparent 70%)",
                    "radial-gradient(circle at center, rgba(56, 189, 248, 0.3) 0%, transparent 70%)",
                    "radial-gradient(circle at center, rgba(139, 92, 246, 0.3) 0%, transparent 70%)"
                  ],
                }}
                transition={{ duration: 2, repeat: Infinity }}
              />
              
              <div className="relative bg-gray-900/90 border border-purple-500/20 rounded-2xl p-12 text-center max-w-md">
                <motion.div
                  initial={{ scale: 0 }}
                  animate={{ scale: 1, rotate: [0, 10, -10, 0] }}
                  transition={{ 
                    scale: { duration: 0.3 },
                    rotate: { duration: 0.5, delay: 0.3 }
                  }}
                  className="mb-6 inline-block"
                >
                  <motion.div
                    animate={{ 
                      boxShadow: [
                        "0 0 20px rgba(139, 92, 246, 0.3)",
                        "0 0 40px rgba(139, 92, 246, 0.6)",
                        "0 0 20px rgba(139, 92, 246, 0.3)"
                      ]
                    }}
                    transition={{ duration: 1.5, repeat: Infinity }}
                    className="p-4 rounded-full bg-purple-500/20"
                  >
                    <Shield className="w-16 h-16 text-purple-400" />
                  </motion.div>
                </motion.div>

                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.4 }}
                  className="space-y-4"
                >
                  <h2 className="text-2xl font-bold text-white font-['Space_Grotesk']">
                    Simulation Access Granted
                  </h2>
                  <p className="text-purple-300 font-['Space_Grotesk']">
                    Initializing neural interface...
                  </p>
                </motion.div>

                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.6 }}
                  className="mt-6 flex justify-center gap-2"
                >
                  <motion.div
                    animate={{ scale: [1, 1.5, 1] }}
                    transition={{ duration: 1, repeat: Infinity, delay: 0 }}
                    className="w-2 h-2 bg-purple-500 rounded-full"
                  />
                  <motion.div
                    animate={{ scale: [1, 1.5, 1] }}
                    transition={{ duration: 1, repeat: Infinity, delay: 0.2 }}
                    className="w-2 h-2 bg-blue-500 rounded-full"
                  />
                  <motion.div
                    animate={{ scale: [1, 1.5, 1] }}
                    transition={{ duration: 1, repeat: Infinity, delay: 0.4 }}
                    className="w-2 h-2 bg-purple-500 rounded-full"
                  />
                </motion.div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <motion.div
        className="fixed inset-0 pointer-events-none"
        animate={{
          background: [
            'radial-gradient(circle at 20% 20%, rgba(139, 92, 246, 0.15) 0%, transparent 50%)',
            'radial-gradient(circle at 80% 80%, rgba(56, 189, 248, 0.15) 0%, transparent 50%)',
            'radial-gradient(circle at 20% 80%, rgba(139, 92, 246, 0.15) 0%, transparent 50%)',
          ]
        }}
        transition={{ duration: 10, repeat: Infinity }}
      />

      <div className="max-w-6xl mx-auto p-8 relative z-10">
        <motion.button
          whileHover={{ scale: 1.05, x: -5 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => navigate('/explorer')}
          className="mb-8 flex items-center gap-2 text-gray-400 hover:text-white transition-colors group"
        >
          <ArrowLeft size={20} className="group-hover:-translate-x-1 transition-transform" />
          <span className="font-['Space_Grotesk']">Return to Explorer</span>
        </motion.button>

        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          className="bg-gradient-to-br from-gray-800/50 to-gray-900/50 backdrop-blur-xl rounded-2xl p-8 border border-purple-500/20 shadow-2xl"
        >
          <div className="flex items-center gap-6 mb-12">
            <motion.div
              whileHover={{ rotate: 360 }}
              transition={{ duration: 0.5 }}
              className="p-4 bg-purple-500/20 rounded-xl border border-purple-500/30"
            >
              <Brain className="w-8 h-8 text-purple-400" />
            </motion.div>
            <div>
              <motion.h1 
                className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-blue-400 font-['Space_Grotesk'] mb-2"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
              >
                {house.house_name}
              </motion.h1>
              <div className="flex items-center gap-3">
                <motion.div 
                  className="flex items-center gap-1 text-green-400"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.3 }}
                >
                  <div className="w-2 h-2 bg-green-400 rounded-full animate-pulse" />
                  <span className="text-sm">ACTIVE</span>
                </motion.div>
                <div className="w-1 h-1 bg-gray-500 rounded-full" />
                {house.house_settings?.type === 'virtuals' && (
                  <>
                    <motion.div 
                      initial={{ opacity: 0, scale: 0 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{ type: "spring", stiffness: 500, damping: 30 }}
                      className="px-3 py-1.5 rounded-full bg-lime-500/20 border border-lime-500 flex items-center gap-2"
                    >
                      <div className="w-2 h-2 rounded-full bg-lime-500 animate-pulse" />
                      <span className="text-lime-500 text-sm font-['Space_Grotesk'] uppercase tracking-wider">VIRTUALS</span>
                    </motion.div>
                    <div className="w-1 h-1 bg-gray-500 rounded-full" />
                  </>
                )}
                <motion.div 
                  className="flex items-center gap-1 text-purple-400"
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.4 }}
                >
                  <Users size={14} />
                  <span className="text-sm">{house.rooms_count} Active Rooms</span>
                </motion.div>
                <div className="w-1 h-1 bg-gray-500 rounded-full" />
                <motion.div 
                  className="flex items-center gap-1 text-blue-400"
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.5 }}
                >
                  <Trophy size={14} />
                  <span className="text-sm">{house.simulation_stats.success_rate.toFixed(1)}% Success Rate</span>
                </motion.div>
              </div>
            </div>
          </div>

          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="mb-12 relative h-[400px] rounded-xl overflow-hidden group"
          >
            <motion.div
              className="absolute inset-0 bg-gradient-to-t from-gray-900 via-transparent to-transparent z-10"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            />
            
            <motion.img
              src={angel}
              alt="Simulation Preview"
              className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-700"
            />
            
            <motion.div
              className="absolute inset-0 bg-purple-500/20 opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-20"
            />
            
            <div className="absolute bottom-0 left-0 right-0 p-8 z-30">
              <motion.div
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.2 }}
                className="grid grid-cols-3 gap-4"
              >
                <div className="px-4 py-3 bg-purple-500/20 backdrop-blur-sm rounded-xl border border-purple-500/30">
                  <div className="text-purple-300 text-xs mb-1">HOUSE ID</div>
                  <div className="text-white font-['Space_Grotesk'] font-bold">#{house.house_backend_id}</div>
                </div>
                <div className="px-4 py-3 bg-blue-500/20 backdrop-blur-sm rounded-xl border border-blue-500/30">
                  <div className="text-blue-300 text-xs mb-1">TOTAL SIMULATIONS</div>
                  <div className="text-white font-['Space_Grotesk'] font-bold">{house.simulation_stats.total_simulations}</div>
                </div>
                <div className="px-4 py-3 bg-green-500/20 backdrop-blur-sm rounded-xl border border-green-500/30">
                  <div className="text-green-300 text-xs mb-1">SUCCESS RATE</div>
                  <div className="text-white font-['Space_Grotesk'] font-bold">{house.simulation_stats.success_rate.toFixed(1)}%</div>
                </div>
              </motion.div>
            </div>
          </motion.div>

          <div className="grid grid-cols-2 gap-6 mb-12">
            <motion.div
              whileHover={{ scale: 1.02 }}
              className="bg-purple-500/10 rounded-xl p-6 border border-purple-500/20"
            >
              <div className="flex items-center gap-3 mb-4">
                <Clock className="w-5 h-5 text-purple-400" />
                <h3 className="text-lg text-purple-400 font-['Space_Grotesk']">Average Iterations</h3>
              </div>
              <div className="text-3xl font-bold text-white font-['Space_Grotesk']">{house.simulation_stats.average_iterations.toFixed(1)}</div>
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.02 }}
              className="bg-blue-500/10 rounded-xl p-6 border border-blue-500/20"
            >
              <div className="flex items-center gap-3 mb-4">
                <Users className="w-5 h-5 text-blue-400" />
                <h3 className="text-lg text-blue-400 font-['Space_Grotesk']">Active Rooms</h3>
              </div>
              <div className="text-3xl font-bold text-white font-['Space_Grotesk']">{house.rooms_count}</div>
            </motion.div>
          </div>

          <div className="space-y-6 mb-12">
            <div>
              <h2 className="text-xl text-purple-400 font-['Space_Grotesk'] mb-4 flex items-center gap-2">
                Task Description <span className="text-sm text-purple-500">//</span>
              </h2>
              <div className="bg-gray-900/50 backdrop-blur-sm rounded-xl p-6 border border-gray-700/30">
                <p className="text-gray-300 font-['Chakra_Petch'] tracking-wide leading-relaxed">
                  {house.task_description}
                </p>
              </div>
            </div>
          </div>

          <div className="flex gap-4">
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={() => handleEnterHouse('simple_prompt')}
              className="flex-1 relative px-8 py-4 rounded-xl overflow-hidden group"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-purple-600 to-blue-600 opacity-80 group-hover:opacity-100 transition-opacity" />
              <div className="absolute inset-0 bg-gradient-to-r from-purple-600 to-blue-600 opacity-0 group-hover:opacity-20 transition-opacity blur-xl" />
              <span className="relative text-white font-['Space_Grotesk'] tracking-wider text-lg flex items-center justify-center gap-2">
                ENTER AS PROMPTOOOOR <Sparkles className="w-5 h-5" />
              </span>
            </motion.button>

            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={() => handleEnterHouse('virtuals')}
              className="flex-1 relative px-8 py-4 rounded-xl overflow-hidden group"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-lime-500 to-green-500 opacity-80 transition-opacity" />
              <div className="absolute inset-0 bg-gradient-to-r from-lime-500 to-green-500 opacity-0 group-hover:opacity-20 transition-opacity blur-xl" />
              <span className="relative text-white font-['Space_Grotesk'] tracking-wider text-lg flex items-center justify-center gap-2">
                ENTER AS VIRTUAL <Sparkles className="w-5 h-5" />
              </span>
            </motion.button>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default IndividualHouse