import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Play, Square, MessageCircle, History, Loader, RefreshCw, Sparkles, Flame, Zap, Skull, Crown, Target, Bomb } from 'lucide-react';
import { useApi } from '../hooks/useApi';
import { useNavigate } from 'react-router-dom';

const TypingAnimation = () => (
  <div className="flex space-x-2 items-center">
    <motion.div
      className="w-2 h-2 bg-purple-500 rounded-full"
      animate={{ scale: [1, 1.2, 1] }}
      transition={{ duration: 1, repeat: Infinity, delay: 0 }}
    />
    <motion.div
      className="w-2 h-2 bg-purple-400 rounded-full"
      animate={{ scale: [1, 1.2, 1] }}
      transition={{ duration: 1, repeat: Infinity, delay: 0.2 }}
    />
    <motion.div
      className="w-2 h-2 bg-purple-300 rounded-full"
      animate={{ scale: [1, 1.2, 1] }}
      transition={{ duration: 1, repeat: Infinity, delay: 0.4 }}
    />
  </div>
);

const LoadingMessage = ({ messageCount }) => {
  const [loadingText, setLoadingText] = useState('Initializing Simulation Environment');
  
  useEffect(() => {
    const messages = [
      'Initializing Simulation Environment',
      'Configuring Agent Parameters',
      'Processing Neural Networks',
      'Analyzing Interaction Patterns',
      'Optimizing Response Algorithms',
      'Synchronizing AI Models',
      'Calibrating Language Processing'
    ];
    
    const interval = setInterval(() => {
      setLoadingText(prev => {
        const currentIndex = messages.indexOf(prev);
        return messages[(currentIndex + 1) % messages.length];
      });
    }, 2000);
    
    return () => clearInterval(interval);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="fixed bottom-8 left-1/2 -translate-x-1/2 bg-gray-900/90 backdrop-blur-xl px-8 py-4 rounded-2xl border border-purple-500/20 shadow-2xl"
    >
      <div className="flex items-center space-x-6">
        <div className="relative">
          <motion.div
            className="absolute inset-0 bg-purple-500/20 rounded-full blur-xl"
            animate={{ 
              scale: [1, 1.5, 1],
              rotate: [0, 180, 360]
            }}
            transition={{ duration: 2, repeat: Infinity }}
          />
          <motion.div
            animate={{ 
              rotate: 360,
              scale: [1, 1.2, 1]
            }}
            transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
            className="text-purple-500 relative"
          >
            <Flame size={24} className="text-orange-500" />
          </motion.div>
        </div>
        <div className="flex flex-col">
          <div className="text-purple-200 font-['Space_Grotesk'] text-sm flex items-center gap-3">
            {loadingText}
            <motion.div
              animate={{ scale: [1, 1.2, 1] }}
              transition={{ duration: 0.5, repeat: Infinity }}
            >
              <Zap className="text-yellow-400" size={16} />
            </motion.div>
          </div>
          {messageCount > 0 && (
            <motion.div 
              initial={{ opacity: 0, y: 5 }}
              animate={{ opacity: 1, y: 0 }}
              className="text-gray-400 text-xs mt-1 flex items-center gap-2"
            >
              <Crown size={12} className="text-yellow-500" />
              <span>{messageCount} power moves executed</span>
            </motion.div>
          )}
        </div>
      </div>
    </motion.div>
  );
};

const SimulationControls = ({
  isSimulating,
  setIsSimulating,
  isPromptSubmitted,
  setMessages,
  messages,
  onViewConversation,
  currentRoomId
}) => {
  const { runSimulation, stopSimulation, getHeartbeat } = useApi();
  const [heartbeatInterval, setHeartbeatInterval] = useState(null);
  const [simulationEnded, setSimulationEnded] = useState(false);
  const navigate = useNavigate();

  const handleSimulationStart = async () => {
    try {
      if (!currentRoomId) {
        console.error('No room ID available for simulation');
        return;
      }

      if (!isPromptSubmitted) {
        console.error('Room not configured yet');
        return;
      }
      
      console.log('Starting simulation for room:', {
        roomId: currentRoomId,
        isPromptSubmitted
      });
      
      const response = await runSimulation(currentRoomId.toString());
      setIsSimulating(true);
      setSimulationEnded(false);
      startHeartbeat();
    } catch (error) {
      console.error('Simulation failed:', error);
      setIsSimulating(false);
    }
  };
  
  const handleResimulate = async () => {
    if (!currentRoomId || !isPromptSubmitted) {
      console.error('Cannot resimulate - room not properly configured');
      return;
    }
    setMessages(null);
    handleSimulationStart();
  };
  
  const handleSimulationStop = async () => {
    try {
      if (!currentRoomId) {
        console.error('No room ID available for stopping simulation');
        return;
      }
      await stopSimulation(currentRoomId.toString());
      setIsSimulating(false);
      clearInterval(heartbeatInterval);
    } catch (error) {
      console.error('Failed to stop simulation:', error);
    }
  };
  
  const startHeartbeat = () => {
    if (!currentRoomId) {
      console.error('No room ID available for heartbeat');
      return;
    }

    const interval = setInterval(async () => {
      try {
        const data = await getHeartbeat(currentRoomId.toString());
        if (data.simulation_object) {
          setMessages(data.simulation_object);
        }
        if (data.simulation_object.system === "task completed") {
          clearInterval(interval);
          setIsSimulating(false);
          setSimulationEnded(true);
          
          // Show success notification
          const notification = document.createElement('div');
          notification.className = 'fixed top-4 right-4 bg-green-500 text-white px-6 py-4 rounded-xl shadow-2xl z-50 flex items-center gap-3 font-[\'Space_Grotesk\']';
          notification.innerHTML = `
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span>Simulation Successfully Completed! 🎉</span>
          `;
          document.body.appendChild(notification);
          
          // Remove notification after 5 seconds
          setTimeout(() => {
            notification.remove();
          }, 5000);
          
          onViewConversation();
        } else if (data.simulation_object.continue === false) {
          clearInterval(interval);
          setIsSimulating(false);
          setSimulationEnded(true);
          onViewConversation();
        }
      } catch (error) {
        console.error('Heartbeat failed:', error);
        clearInterval(interval);
        setIsSimulating(false);
      }
    }, 6000);
    setHeartbeatInterval(interval);
  };

  useEffect(() => {
    return () => {
      if (heartbeatInterval) {
        clearInterval(heartbeatInterval);
      }
    };
  }, [heartbeatInterval]);

  const messageCount = messages?.conversation?.length || 0;

  return (
    <>
      <div className="mt-8 flex justify-center gap-4">
        <AnimatePresence mode="wait">
          {!isSimulating ? (
            <motion.div
              key="not-simulating"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="flex gap-4"
            >
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={simulationEnded ? handleResimulate : handleSimulationStart}
                disabled={!isPromptSubmitted}
                className={`group relative px-8 py-4 rounded-xl overflow-hidden ${
                  !isPromptSubmitted ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              >
                <motion.div 
                  className="absolute inset-0 bg-gradient-to-r from-purple-600 via-red-500 to-orange-500"
                  animate={{
                    backgroundPosition: ['0% 50%', '100% 50%', '0% 50%'],
                  }}
                  transition={{ duration: 3, repeat: Infinity, ease: "linear" }}
                />
                <motion.div
                  className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                  style={{
                    background: 'radial-gradient(circle at center, rgba(239, 68, 68, 0.5) 0%, transparent 70%)',
                  }}
                />
                <div className="relative flex items-center gap-3 text-white font-['Space_Grotesk'] tracking-wider">
                  {simulationEnded ? (
                    <>
                      <Target className="animate-spin-slow" size={24} />
                      <span className="text-lg">RUN NEW SIMULATION</span>
                    </>
                  ) : (
                    <>
                      <Sparkles className="animate-pulse" size={24} />
                      <span className="text-lg">START SIMULATION</span>
                    </>
                  )}
                </div>
              </motion.button>
              {simulationEnded && (
                <>
                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={onViewConversation}
                    className="group relative px-6 py-2 rounded-xl overflow-hidden"
                  >
                    <div className="absolute inset-0 bg-gradient-to-r from-indigo-600 to-purple-700 transition-all group-hover:scale-105"></div>
                    <div className="relative flex items-center space-x-2 text-white font-['Chakra_Petch'] tracking-wider">
                      <MessageCircle size={16} />
                      <span>VIEW SIMULATION</span>
                    </div>
                  </motion.button>
                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={() => navigate('/history')}
                    className="group relative px-6 py-2 rounded-xl overflow-hidden"
                  >
                    <div className="absolute inset-0 bg-gradient-to-r from-blue-600 to-cyan-700 transition-all group-hover:scale-105"></div>
                    <div className="relative flex items-center space-x-2 text-white font-['Chakra_Petch'] tracking-wider">
                      <History size={16} />
                      <span>SIMULATION HISTORY</span>
                    </div>
                  </motion.button>
                </>
              )}
            </motion.div>
          ) : (
            <motion.div
              key="simulating"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="flex gap-4"
            >
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleSimulationStop}
                className="group relative px-8 py-4 rounded-xl overflow-hidden"
              >
                <div className="absolute inset-0 bg-gradient-to-r from-red-500 via-orange-500 to-red-500" />
                <motion.div
                  className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                  style={{
                    background: 'radial-gradient(circle at center, rgba(239, 68, 68, 0.5) 0%, transparent 70%)',
                  }}
                />
                <div className="relative flex items-center gap-3 text-white font-['Space_Grotesk'] tracking-wider">
                  <Square className="animate-pulse" size={24} />
                  <span className="text-lg">STOP SIMULATION</span>
                </div>
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={onViewConversation}
                className="group relative px-6 py-2 rounded-xl overflow-hidden"
              >
                <div className="absolute inset-0 bg-gradient-to-r from-indigo-600 to-purple-700 transition-all group-hover:scale-105"></div>
                <div className="relative flex items-center space-x-2 text-white font-['Chakra_Petch'] tracking-wider">
                  <MessageCircle size={16} />
                  <span>VIEW PROGRESS</span>
                </div>
              </motion.button>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <motion.div
        className="fixed inset-0 pointer-events-none z-0"
        animate={{
          background: [
            'radial-gradient(circle at center, rgba(239, 68, 68, 0.1) 0%, transparent 50%)',
            'radial-gradient(circle at center, rgba(139, 92, 246, 0.1) 0%, transparent 50%)',
            'radial-gradient(circle at center, rgba(239, 68, 68, 0.1) 0%, transparent 50%)',
          ],
        }}
        transition={{ duration: 5, repeat: Infinity }}
      />

      <AnimatePresence>
        {isSimulating && (
          <LoadingMessage messageCount={messageCount} />
        )}
      </AnimatePresence>
    </>
  );
};

export default SimulationControls;