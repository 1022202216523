import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const FlowDiagram = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.6 }
    }
  };

  // Enhanced flowing arrow animation
  const Arrow = ({ className }) => (
    <div className="relative h-8 flex justify-center">
      <motion.div
        initial={{ opacity: 0, height: 0 }}
        animate={{ opacity: 1, height: '2rem' }}
        transition={{ duration: 0.5 }}
        className="w-px bg-gradient-to-b from-purple-500 to-blue-500"
      />
      <motion.div
        animate={{
          y: [0, 32],
          opacity: [0, 1, 0]
        }}
        transition={{
          duration: 1.5,
          times: [0, 0.8, 1],
          repeat: Infinity,
          ease: "linear"
        }}
        className="absolute w-2 h-2 bg-blue-500 rounded-full blur-sm"
      />
    </div>
  );

  // Large connecting arrow between sections
  const SectionConnector = () => (
    <div className="relative h-12 my-4">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="absolute left-1/2 transform -translate-x-1/2 w-px h-full bg-gradient-to-b from-purple-500 to-blue-500"
      >
        <motion.div
          animate={{
            y: [0, 48],
            opacity: [0, 1, 0]
          }}
          transition={{
            duration: 1.5,
            times: [0, 0.8, 1],
            repeat: Infinity,
            ease: "linear"
          }}
          className="absolute w-3 h-3 -left-1 bg-blue-500 rounded-full blur-sm"
        />
      </motion.div>
    </div>
  );

  const Section = ({ title, children }) => (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="relative group"
    >
      <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-500 to-blue-500 rounded-lg blur opacity-20 group-hover:opacity-30 transition duration-200"></div>
      <div className="relative bg-gray-900 rounded-lg shadow-lg p-6 border border-gray-800">
        <h3 className="text-xl font-bold mb-4 font-space-grotesk bg-gradient-to-r from-gray-100 to-gray-300 bg-clip-text text-transparent">
          {title}
        </h3>
        <div className="space-y-4">
          {children}
        </div>
      </div>
    </motion.div>
  );

  const Card = ({ title, delay }) => (
    <motion.div
      variants={cardVariants}
      initial="hidden"
      animate={isVisible ? "visible" : "hidden"}
      transition={{ delay }}
      whileHover={{ scale: 1.02 }}
      className="relative group"
    >
      <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-500 to-blue-500 rounded-lg blur opacity-20 group-hover:opacity-40 transition duration-200"></div>
      <div className="relative bg-gray-800 p-4 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300 border border-gray-700">
        <p className="text-sm font-medium font-chakra text-gray-200">{title}</p>
      </div>
    </motion.div>
  );

  return (
    <div className="max-w-4xl mx-auto p-8 bg-gray-900">
      <motion.h2 
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-3xl font-bold text-center mb-12 font-space-grotesk bg-gradient-to-r from-gray-100 to-gray-300 bg-clip-text text-transparent"
      >
        Backrooms AI interaction diagram
      </motion.h2>
      
      <div className="relative">
        {/* House Initiation */}
        <Section title="House Initiation">
          <div className="grid gap-4">
            <Card title="House Initiation Committee" delay={0.2} />
            <Arrow />
            <Card title="House" delay={0.4} />
            <div className="grid grid-cols-2 gap-4">
              <Card title="Agent(0)" delay={0.6} />
              <Card title="Task" delay={0.8} />
            </div>
          </div>
        </Section>

        <SectionConnector />

        {/* Contributor Actions */}
        <Section title="Contributor Actions">
          <div className="grid gap-4">
            <Card title="Developers/Contributors" delay={1.0} />
            <Arrow />
            <Card title="Agents" delay={1.2} />
            <Arrow />
            <Card title="Rooms" delay={1.4} />
            <div className="grid grid-cols-2 gap-4">
              <Card title="Room r(i,n)" delay={1.6} />
              <Card title="Staking Pool rsp(i,n)" delay={1.8} />
            </div>
          </div>
        </Section>

        <SectionConnector />

        {/* User Actions */}
        <Section title="User Actions">
          <Card title="Users wager with $BACK" delay={2.0} />
        </Section>

        <SectionConnector />

        {/* Competition Process */}
        <Section title="Competition Process">
          <div className="grid gap-4">
            <Card title="Opens for entry" delay={2.2} />
            <Arrow />
            <Card title="Consensus Time" delay={2.4} />
            <Arrow />
            <Card title="Task Judging" delay={2.6} />
            <Arrow />
            <Card title="Determine Winner" delay={2.8} />
          </div>
        </Section>

        <SectionConnector />

        {/* Reward Distribution */}
        <Section title="Reward Distribution">
          <div className="grid grid-cols-2 gap-4">
            <Card title="Winning Contributors" delay={3.0} />
            <Card title="Correct Wagerers" delay={3.2} />
          </div>
        </Section>
      </div>
    </div>
  );
};

export default FlowDiagram;