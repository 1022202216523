import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { UserCircle2, History, Layout, Info } from 'lucide-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import logo from '../assets/backrooms.svg';
import { useWeb3 } from '../contexts/Web3Context';

const Navbar = () => {
  const location = useLocation();
  const { activeWallet, setActiveWallet } = useWeb3();
  const [isOpen, setIsOpen] = useState(false);

  const links = [
    { to: '/account', icon: <UserCircle2 size={24} />, text: 'Sandbox' },
    { to: '/explorer', icon: <Layout size={24} />, text: 'Explorer' },
    { to: '/history', icon: <History size={24} />, text: 'History' },
    { to: '/about', icon: <Info size={24} />, text: 'About' },
  ];

  return (
    <nav className="bg-gray-900/80 backdrop-blur-md border-b border-gray-800 sticky top-0 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          {/* Logo */}
          <Link to="/" className="flex-shrink-0">
            <img className="h-8 w-auto" src={logo} alt="Backrooms" />
          </Link>

          {/* Navigation Links - Desktop */}
          <div className="hidden md:flex items-center space-x-4">
            {links.map((link) => (
              <Link
                key={link.to}
                to={link.to}
                className={`px-3 py-2 rounded-md text-sm font-medium transition-colors duration-150 ease-in-out flex items-center space-x-2
                  ${location.pathname === link.to
                    ? 'text-white bg-gray-800'
                    : 'text-gray-300 hover:text-white hover:bg-gray-700'
                  }`}
              >
                {link.icon}
                <span>{link.text}</span>
              </Link>
            ))}
          </div>

          {/* Wallet Connection */}
          <div className="flex items-center space-x-4">
            <div className="flex items-center space-x-2 bg-gray-800 rounded-lg p-1">
              <button
                onClick={() => setActiveWallet('solana')}
                className={`px-3 py-1 rounded-md text-sm font-medium transition-colors duration-150 ease-in-out
                  ${activeWallet === 'solana'
                    ? 'bg-purple-600 text-white'
                    : 'text-gray-300 hover:text-white'
                  }`}
              >
                Solana
              </button>
              <button
                onClick={() => setActiveWallet('base')}
                className={`px-3 py-1 rounded-md text-sm font-medium transition-colors duration-150 ease-in-out
                  ${activeWallet === 'base'
                    ? 'bg-purple-600 text-white'
                    : 'text-gray-300 hover:text-white'
                  }`}
              >
                Base
              </button>
            </div>
            
            {activeWallet === 'solana' ? (
              <WalletMultiButton className="!bg-purple-600 hover:!bg-purple-700" />
            ) : (
              <ConnectButton />
            )}
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden flex items-center">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className={`${isOpen ? 'hidden' : 'block'} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
              <svg
                className={`${isOpen ? 'block' : 'hidden'} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            className="md:hidden"
          >
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              {links.map((link) => (
                <Link
                  key={link.to}
                  to={link.to}
                  className={`block px-3 py-2 rounded-md text-base font-medium ${
                    location.pathname === link.to
                      ? 'text-white bg-gray-800'
                      : 'text-gray-300 hover:text-white hover:bg-gray-700'
                  } flex items-center space-x-2`}
                  onClick={() => setIsOpen(false)}
                >
                  {link.icon}
                  <span>{link.text}</span>
                </Link>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
};

export default Navbar;