import { motion, useMotionValue, useTransform, useSpring, AnimatePresence } from 'framer-motion';
import { useRef, useEffect, useState, useMemo } from 'react';
import { Canvas } from '@react-three/fiber';
import { ParticlesStuff } from './Particles';
import CyberButton from './CyberButton';
import { useNavigate } from 'react-router-dom';

const FloatingText = ({ children, delay = 0, mousePosition }) => {
  const float = useTransform(
    mousePosition.x,
    [-1, 1],
    [-10, 10]
  );

  const float2 = useTransform(
    mousePosition.y,
    [-1, 1],
    [-5, 5]
  );

  return (
    <motion.span
      initial={{ opacity: 0, y: 20 }}
      animate={{ 
        opacity: 1, 
        y: 0,
        transition: {
          duration: 0.5,
          delay,
          ease: "easeOut"
        }
      }}
      style={{ 
        x: float,
        y: float2,
        display: 'inline-block',
        position: 'relative'
      }}
      whileHover={{
        scale: 1.2,
        color: '#fff',
        transition: { duration: 0.2 }
      }}
      className="cursor-pointer"
    >
      {children}
    </motion.span>
  );
};

const InteractiveGrid = ({ mousePosition }) => {
  const points = useMemo(() => {
    const pts = [];
    const count = 40;
    for (let i = 0; i < count; i++) {
      for (let j = 0; j < count; j++) {
        pts.push({
          x: (i - count/2) * 50,
          y: (j - count/2) * 50
        });
      }
    }
    return pts;
  }, []);

  return (
    <div className="absolute inset-0 overflow-hidden">
      {points.map((point, i) => (
        <motion.div
          key={i}
          className="absolute w-1 h-1 bg-purple-500/30"
          style={{
            left: `${point.x + window.innerWidth/2}px`,
            top: `${point.y + window.innerHeight/2}px`,
          }}
          animate={{
            scale: [
              1,
              Math.abs(Math.sin((point.x * mousePosition.x + point.y * mousePosition.y) / 10000)) * 3 + 1
            ],
            opacity: [
              0.3,
              Math.abs(Math.cos((point.x * mousePosition.x + point.y * mousePosition.y) / 10000)) * 0.5 + 0.3
            ]
          }}
          transition={{ duration: 0.5 }}
        />
      ))}
    </div>
  );
};

const EnergyField = ({ mousePosition }) => {
  return (
    <motion.div 
      className="absolute inset-0 pointer-events-none"
      style={{
        background: `radial-gradient(circle at ${50 + mousePosition.x * 50}% ${50 + mousePosition.y * 50}%, rgba(139, 92, 246, 0.15), transparent 70%)`
      }}
    />
  );
};

const CyberRings = ({ mousePosition }) => {
  return (
    <div className="absolute inset-0 pointer-events-none">
      {[...Array(3)].map((_, i) => (
        <motion.div
          key={i}
          className="absolute inset-0"
          style={{
            border: `${1 + i}px solid rgba(139, 92, 246, ${0.1 - i * 0.02})`,
            borderRadius: '50%',
            transform: 'translate(-50%, -50%)',
            left: `${50 + mousePosition.x * (10 + i * 5)}%`,
            top: `${50 + mousePosition.y * (10 + i * 5)}%`,
          }}
          animate={{
            scale: [1, 1.5, 1],
            opacity: [0.3, 0.1, 0.3],
          }}
          transition={{
            duration: 3,
            repeat: Infinity,
            delay: i * 0.2,
          }}
        />
      ))}
    </div>
  );
};

const Hero = () => {
  const containerRef = useRef(null);
  const mouseX = useMotionValue(0);
  const mouseY = useMotionValue(0);
  const [isHovering, setIsHovering] = useState(false);
  const navigate = useNavigate();

  const smoothMouseX = useSpring(mouseX, { damping: 50, stiffness: 400 });
  const smoothMouseY = useSpring(mouseY, { damping: 50, stiffness: 400 });

  useEffect(() => {
    const handleMouseMove = (e) => {
      const { clientX, clientY } = e;
      const { width, height } = containerRef.current.getBoundingClientRect();
      const x = (clientX / width - 0.5) * 2;
      const y = (clientY / height - 0.5) * 2;
      mouseX.set(x);
      mouseY.set(y);
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, [mouseX, mouseY]);

  const title = "BACKROOMS".split("");

  const handleEnterArena = () => {
    navigate('/explorer');
  };

  return (
    <motion.div 
      ref={containerRef}
      className="relative min-h-screen flex items-center justify-center overflow-hidden bg-gray-900"
      onHoverStart={() => setIsHovering(true)}
      onHoverEnd={() => setIsHovering(false)}
    >
      <div className="hidden md:block">
        <ParticlesStuff />
        <InteractiveGrid mousePosition={{ x: smoothMouseX, y: smoothMouseY }} />
        <EnergyField mousePosition={{ x: smoothMouseX, y: smoothMouseY }} />
        <CyberRings mousePosition={{ x: smoothMouseX, y: smoothMouseY }} />
      </div>

      <div className="absolute inset-0 md:hidden">
        <motion.div
          className="absolute inset-0"
          animate={{
            background: [
              'radial-gradient(circle at 50% 50%, rgba(139, 92, 246, 0.15), transparent 70%)',
              'radial-gradient(circle at 50% 50%, rgba(34, 211, 238, 0.15), transparent 70%)',
            ]
          }}
          transition={{ duration: 5, repeat: Infinity, repeatType: "reverse" }}
        />
      </div>

      <div className="relative z-10 text-center px-4">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          className="mb-8 perspective-1000"
        >
          <h1 className="relative text-4xl sm:text-6xl md:text-7xl lg:text-9xl font-bold font-space-grotesk tracking-wider">
            {title.map((letter, index) => (
              <FloatingText 
                key={index} 
                delay={index * 0.1}
                mousePosition={{ x: smoothMouseX, y: smoothMouseY }}
              >
                <span className="relative inline-block">
                  <motion.span
                    className="absolute inset-0 bg-gradient-to-r from-blue-500 to-purple-500 bg-clip-text text-transparent blur-sm"
                    animate={{
                      transform: [
                        'translate(0px, 0px)',
                        'translate(2px, -2px)',
                        'translate(0px, 0px)'
                      ]
                    }}
                    transition={{
                      duration: 2,
                      repeat: Infinity,
                      ease: "easeInOut"
                    }}
                  >
                    {letter}
                  </motion.span>
                  <motion.span 
                    className="relative bg-gradient-to-r from-gray-100 to-gray-300 bg-clip-text text-transparent"
                    whileHover={{
                      color: '#fff',
                      textShadow: '0 0 8px rgba(139, 92, 246, 0.8)'
                    }}
                  >
                    {letter}
                  </motion.span>
                </span>
              </FloatingText>
            ))}
          </h1>
        </motion.div>

        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 1.5, duration: 0.8 }}
          className="text-xl sm:text-2xl md:text-3xl text-gray-300 mb-8 md:mb-12 font-space-grotesk relative max-w-3xl mx-auto"
          style={{
            x: useTransform(smoothMouseX, [-1, 1], [-20, 20]),
            y: useTransform(smoothMouseY, [-1, 1], [-10, 10]),
          }}
        >
          <motion.span
            className="absolute -inset-1 blur-sm opacity-50"
            animate={{
              background: [
                'linear-gradient(90deg, #9333ea, #22d3ee)',
                'linear-gradient(180deg, #22d3ee, #9333ea)',
                'linear-gradient(270deg, #9333ea, #22d3ee)',
              ]
            }}
            transition={{ duration: 4, repeat: Infinity }}
          />
          The First Competitive Agent-To-Agent Combat Arena
        </motion.p>

        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: 2, duration: 0.5 }}
        >
          <CyberButton onClick={handleEnterArena}>Enter the Arena</CyberButton>
        </motion.div>
      </div>

      <div className="absolute inset-0 overflow-hidden hidden md:block">
        <CornerDecorations mousePosition={{ x: smoothMouseX, y: smoothMouseY }} />
      </div>
    </motion.div>
  );
};

// Create a separate file for these components
const GridPlane = ({ mousePosition }) => {
  const meshRef = useRef();

  useEffect(() => {
    if (meshRef.current) {
      meshRef.current.rotation.x = mousePosition.y * 0.1;
      meshRef.current.rotation.y = mousePosition.x * 0.1;
    }
  }, [mousePosition]);

  return (
    <mesh ref={meshRef}>
      <planeGeometry args={[20, 20, 20, 20]} />
      <meshStandardMaterial 
        wireframe 
        color="#8b5cf6"
        opacity={0.1}
        transparent
      />
    </mesh>
  );
};

const CornerDecorations = ({ mousePosition }) => {
  const cornerVariants = {
    initial: { scale: 1, rotate: 0 },
    animate: (corner) => ({
      scale: [1, 1.2, 1],
      rotate: [0, 180, 360],
      transition: {
        duration: 20,
        repeat: Infinity,
        ease: "linear",
        delay: corner * 0.5,
      }
    })
  };

  const glowVariants = {
    initial: { opacity: 0.3 },
    animate: {
      opacity: [0.3, 0.6, 0.3],
      transition: {
        duration: 2,
        repeat: Infinity,
        ease: "easeInOut"
      }
    }
  };

  return (
    <>
      {[0, 1, 2, 3].map((corner) => {
        const isTop = corner < 2;
        const isLeft = corner % 2 === 0;
        
        return (
          <motion.div
            key={corner}
            className="absolute w-48 h-48"
            style={{
              top: isTop ? '5%' : 'auto',
              bottom: !isTop ? '5%' : 'auto',
              left: isLeft ? '5%' : 'auto',
              right: !isLeft ? '5%' : 'auto',
            }}
            animate={{
              x: isLeft ? 
                mousePosition.x * 20 : 
                mousePosition.x * -20,
              y: isTop ? 
                mousePosition.y * 20 : 
                mousePosition.y * -20,
              transition: {
                type: "spring",
                stiffness: 150,
                damping: 15
              }
            }}
          >
            {/* Outer rotating circle */}
            <motion.div
              className="w-full h-full relative"
              variants={cornerVariants}
              initial="initial"
              animate="animate"
              custom={corner}
            >
              <div className="absolute inset-0 border-2 border-purple-500/30 rounded-full" />
              
              {/* Inner rotating circle */}
              <motion.div
                className="absolute inset-4 border-2 border-cyan-500/30 rounded-full"
                animate={{
                  rotate: [0, -180, -360],
                  scale: [1, 0.8, 1],
                }}
                transition={{
                  duration: 15,
                  repeat: Infinity,
                  ease: "linear",
                }}
              />

              {/* Glowing center */}
              <motion.div
                className="absolute inset-8 rounded-full bg-gradient-to-r from-purple-500/20 to-cyan-500/20 blur-md"
                variants={glowVariants}
                initial="initial"
                animate="animate"
              />

              {/* Decorative lines */}
              {[...Array(4)].map((_, index) => (
                <motion.div
                  key={index}
                  className="absolute top-1/2 left-1/2 h-0.5 w-24 origin-left"
                  style={{
                    background: "linear-gradient(90deg, rgba(139, 92, 246, 0.5), rgba(34, 211, 238, 0))",
                    transform: `rotate(${index * 90}deg)`,
                  }}
                  animate={{
                    opacity: [0.3, 0.7, 0.3],
                    scale: [1, 1.2, 1],
                  }}
                  transition={{
                    duration: 3,
                    repeat: Infinity,
                    ease: "easeInOut",
                    delay: index * 0.2,
                  }}
                />
              ))}
            </motion.div>
          </motion.div>
        );
      })}
    </>
  );
};

export default Hero; 