import axios from 'axios';
import { useCallback } from 'react';
import { useWeb3 } from '../contexts/Web3Context';

const api = axios.create({ 
  baseURL: process.env.REACT_APP_API_URL,
});

export const useApi = () => {
  const { address, activeWallet } = useWeb3();

  // Format wallet address based on the active wallet type
  const getFormattedAddress = useCallback(() => {
    if (!address) return null;
    
    return address;
  }, [address, activeWallet]);

  // Creator routes
  const createCreator = useCallback(async () => {
    const formattedAddress = getFormattedAddress();
    if (!formattedAddress) return;
    
    const response = await api.post('/creator/create', { 
      wallet_address: formattedAddress,
       // Add wallet type to the request
    });
    return response.data;
  }, [getFormattedAddress, activeWallet]);

  const checkUserExists = useCallback(async () => {
    const formattedAddress = getFormattedAddress();
    if (!formattedAddress) return false;

    console.log('Checking user exists for:', formattedAddress);
    try {
      const response = await api.get('/creator/exists', {
        params: { 
          wallet_address: formattedAddress,
          
        }
      });
      console.log('Check user response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Check user error:', error);
      if (error.response?.status === 404) {
        return false;
      }
      throw error;
    }
  }, [getFormattedAddress, activeWallet]);

  const getHistory = useCallback(async () => {
    const formattedAddress = getFormattedAddress();
    if (!formattedAddress) return [];

    const response = await api.get('/creator/history', {
      params: { 
        wallet_address: formattedAddress,
        
      }
    });
    return response.data;
  }, [getFormattedAddress, activeWallet]);

  // Simulation routes
  const runSimulation = useCallback(async (room_id) => {
    const formattedAddress = getFormattedAddress();
    if (!formattedAddress) return;

    const response = await api.post('/simulation/run', { 
      wallet_address: formattedAddress,
      
      room_id: room_id
    });
    return response.data;
  }, [getFormattedAddress, activeWallet]);
  
  const stopSimulation = useCallback(async (room_id) => {
    const formattedAddress = getFormattedAddress();
    if (!formattedAddress) return;

    const response = await api.post('/simulation/stop', { 
      wallet_address: formattedAddress,
      
      room_id: room_id
    });
    return response.data;
  }, [getFormattedAddress, activeWallet]);
  
  const getHeartbeat = useCallback(async (room_id) => {
    const formattedAddress = getFormattedAddress();
    if (!formattedAddress) return;

    console.log('getting heartbeat for ', room_id);
    const response = await api.post('/simulation/heartbeat', {
      wallet_address: formattedAddress,
      
      room_id: room_id
    });
    console.log('heartbeat response ', response.data);
    return response.data;
  }, [getFormattedAddress, activeWallet]);

  // House routes
  const createHouse = useCallback(async (house_name, house_type, house_settings, house_system_prompt, task_description, hours, minutes) => {
    const formattedAddress = getFormattedAddress();
    if (!formattedAddress) return;

    const response = await api.post('/house/create', { 
      wallet_address: formattedAddress,
      house_name,
      house_type,
      house_settings,
      house_system_prompt,
      task_description,
      hours,
      minutes
    });
    return response.data;
  }, [getFormattedAddress, activeWallet]);

  const enterRoom = useCallback(async (house_id, room_type = 'simple_prompt') => {
    const formattedAddress = getFormattedAddress();
    if (!formattedAddress) return;

    const response = await api.post('/house/enter_room', { 
      wallet_address: formattedAddress,
      house_id: house_id,
      room_type: room_type
    });
    return response.data;
  }, [getFormattedAddress, activeWallet]);

  const leaveRoom = useCallback(async (room_id) => {
    const formattedAddress = getFormattedAddress();
    if (!formattedAddress) return;

    const response = await api.post(`/house/leave_room/${room_id}`, {
      wallet_address: formattedAddress,
      
    });
    return response.data;
  }, [getFormattedAddress, activeWallet]);

  const listHouses = useCallback(async (house_id = undefined) => {

    try {
      const params = {
        
        ...(house_id ? { house_id } : {}),
      };
      const response = await api.get('/house/list', { params });
      return response.data;
    } catch (error) {
      if (error.response?.status === 404) {
        return false;
      }
      throw error;
    }
  }, [getFormattedAddress, activeWallet]);

  const listRooms = useCallback(async () => {

    try {
      const response = await api.get(`/house/list_rooms/${address}`, {
        params: {  }
      });
      return response.data;
    } catch (error) {
      if (error.response?.status === 404) {
        return false;
      }
      throw error;
    }
  }, [getFormattedAddress, activeWallet]);

  const setRoomPrompt = useCallback(async (room_id, room_prompt) => {
    const formattedAddress = getFormattedAddress();
    if (!formattedAddress) return;

    console.log('setting room prompt for ', room_id, ' with prompt ', room_prompt);

    const response = await api.post('/house/simple_prompt_room_settings', {
      room_id: room_id,
      room_prompt: room_prompt,
      wallet_address: formattedAddress,
    });
    return response.data;
  }, [getFormattedAddress, activeWallet]);

  const setVirtualsRoomSettings = useCallback(async (room_id, virtual_uid, additional_context) => {
    const formattedAddress = getFormattedAddress();
    if (!formattedAddress) return;

    console.log('Setting virtuals room settings:', { room_id, virtual_uid, additional_context });
    const response = await api.post('/house/virtuals_room_settings', {
      room_id,
      virtual_uid,
      wallet_address: formattedAddress,
      additional_context: `You are on the Backrooms AI platform. ${additional_context}`,
      first_message_to_virtual: "You start the dialogue"
    });
    return response.data;
  }, [getFormattedAddress, activeWallet]);

  return {
    // Creator endpoints
    createCreator,
    checkUserExists,
    getHistory,
    
    // Simulation endpoints
    runSimulation,
    stopSimulation,
    getHeartbeat,
    
    // House endpoints
    createHouse,
    enterRoom,
    leaveRoom,
    listHouses,
    listRooms,
    setRoomPrompt,
    setVirtualsRoomSettings
  };
};