import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { MessageCircle, Download, History, Loader } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import Modal from './Modal';

const TypingAnimation = () => (
  <div className="flex space-x-2 items-center">
    <motion.div
      className="w-2 h-2 bg-purple-500 rounded-full"
      animate={{ scale: [1, 1.2, 1] }}
      transition={{ duration: 1, repeat: Infinity, delay: 0 }}
    />
    <motion.div
      className="w-2 h-2 bg-purple-400 rounded-full"
      animate={{ scale: [1, 1.2, 1] }}
      transition={{ duration: 1, repeat: Infinity, delay: 0.2 }}
    />
    <motion.div
      className="w-2 h-2 bg-purple-300 rounded-full"
      animate={{ scale: [1, 1.2, 1] }}
      transition={{ duration: 1, repeat: Infinity, delay: 0.4 }}
    />
  </div>
);

const LoadingMessages = ({ isSimulating }) => (
  <div className="flex flex-col items-center justify-center space-y-6 py-12">
    <motion.div
      animate={{ rotate: 360 }}
      transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
      className="text-purple-500"
    >
      <Loader size={32} />
    </motion.div>
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="text-gray-400 font-['Space_Grotesk'] flex items-center gap-3"
    >
      {isSimulating ? (
        <>
          <span>AI is thinking</span>
          <TypingAnimation />
        </>
      ) : (
        <span>Waiting for simulation to begin...</span>
      )}
    </motion.div>
  </div>
);

const MessageCard = ({ message, index }) => {
  const role = Object.keys(message)[0];
  const content = Object.values(message)[0];
  
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: index * 0.1 }}
      className="bg-gray-900/50 backdrop-blur-sm rounded-xl p-5 border border-gray-700/50 hover:border-gray-600/50 transition-colors"
    >
      <div className="font-['Chakra_Petch'] tracking-wider text-purple-400 mb-2 uppercase text-sm">
        {role}
      </div>
      <div className="text-gray-300 font-['Space_Grotesk'] tracking-wide whitespace-pre-wrap text-sm leading-relaxed">
        {content}
      </div>
    </motion.div>
  );
};

const ConversationModal = ({ isOpen, onClose, messages, isSimulating }) => {
  const navigate = useNavigate();
  const isSimulationComplete = messages?.continue === false;

  const downloadConversation = () => {
    if (!messages?.conversation) return;
    
    const text = messages.conversation
      .map(m => `${Object.keys(m)[0]}: ${Object.values(m)[0]}`)
      .join('\n\n');
    
    const blob = new Blob([text], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'conversation.txt';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const getMessages = () => {
    if (!messages?.conversation) return [];
    return messages.conversation;
  };

  const AnimatedButton = ({ children, onClick, className }) => (
    <motion.button
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      onClick={onClick}
      className={className}
    >
      {children}
    </motion.button>
  );

  const hasMessages = getMessages().length > 0;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="p-8">
        <motion.div 
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.1 }}
          className="flex justify-between items-center mb-8"
        >
          <div className="flex items-center space-x-4">
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ delay: 0.2, type: "spring" }}
            >
              <MessageCircle size={32} className="text-purple-400" />
            </motion.div>
            <div>
              <h2 className="text-2xl font-bold text-gray-100 font-['Space_Grotesk']">
                Conversation
              </h2>
              {isSimulationComplete && (
                <motion.div
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  className="mt-1 inline-flex items-center"
                >
                  <span className="text-sm bg-gradient-to-r from-green-500 to-emerald-500 text-white px-3 py-1 rounded-full font-medium">
                    Complete
                  </span>
                </motion.div>
              )}
              {isSimulating && hasMessages && (
                <motion.div
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  className="mt-2 flex items-center gap-2 text-purple-400"
                >
                  <TypingAnimation />
                  <span className="text-sm">AI is responding...</span>
                </motion.div>
              )}
            </div>
          </div>
          
          <div className="flex gap-3">
            <AnimatedButton
              onClick={downloadConversation}
              className="relative px-4 py-2.5 rounded-xl overflow-hidden group"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-blue-500 to-purple-500 opacity-80 group-hover:opacity-100 transition-opacity" />
              <div className="relative flex items-center space-x-2 text-white font-['Chakra_Petch'] tracking-wider">
                <Download size={16} />
                <span>DOWNLOAD</span>
              </div>
            </AnimatedButton>
            
            {isSimulationComplete && (
              <AnimatedButton
                onClick={() => navigate('/history')}
                className="relative px-4 py-2.5 rounded-xl overflow-hidden group"
              >
                <div className="absolute inset-0 bg-gradient-to-r from-blue-600 to-blue-700 opacity-80 group-hover:opacity-100 transition-opacity" />
                <div className="relative flex items-center space-x-2 text-white font-['Chakra_Petch'] tracking-wider">
                  <History size={16} />
                  <span>HISTORY</span>
                </div>
              </AnimatedButton>
            )}
          </div>
        </motion.div>

        <motion.div 
          className="space-y-4 max-h-[60vh] overflow-y-auto pr-4 custom-scrollbar"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
        >
          {!hasMessages ? (
            <LoadingMessages isSimulating={isSimulating} />
          ) : (
            <>
              {getMessages().map((message, index) => (
                <MessageCard key={index} message={message} index={index} />
              ))}
            </>
          )}
        </motion.div>

        {isSimulationComplete && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
            className="mt-6 p-5 bg-gray-900/50 backdrop-blur-sm rounded-xl border border-gray-700/50"
          >
            <p className="text-gray-300 text-sm flex items-center space-x-2">
              <motion.span 
                className="w-2 h-2 bg-green-500 rounded-full inline-block"
                animate={{ scale: [1, 1.2, 1] }}
                transition={{ duration: 2, repeat: Infinity }}
              />
              <span>
                Simulation complete. You can download this conversation or view it in your history.
              </span>
            </p>
          </motion.div>
        )}
      </div>
    </Modal>
  );
};

export default ConversationModal;